export const environment = {
    webToken: '%!ng4cc355T0k3n@123%!^123590abcd',
    environment: 'prod',
    cardServiceDomain: 'https://cs.nextgem.com',
    userServiceDomain: 'https://us.nextgem.com',
    production: false,
    cardDetailsURL: 'https://cs.nextgem.com/carddetails/',
    cardCollectionURL: 'https://cs.nextgem.com/cards/collection/',
    profileURL: 'https://pi.nextgem.com',
    imageURL: 'https://img.nextgem.com',
    deploymentType: 'PRODUCTION',
    domainName: 'nextgem.com',
    baseURL: ''
};
