<div class="card-details-view-container">
    <div class="card-title">
        <h4 class="card-details_heading pt-0 desk-card-details data_grid">{{cardDetails?.title}}</h4>

    </div>



    <div class="card-details-header m-0">

        <div class="buy-btn">

            <button *ngIf="cardDetails?.showSendOfferButton" (click)="sendEventToparent('send_offer')">
                <span>
                    <mat-icon>local_offer</mat-icon>
                </span>
                <span>Make an Offer
                </span>
            </button>


        </div>
        <div class="buy-btn" *ngIf="showBuyNow">
            <button (click)="sendEventToparent('buy_now')">Buy Now <span>{{cardDetails?.listingprice|
                    currency:'USD':'symbol'}}</span></button>
        </div>
    </div>


    <div class="tab-container">
        <mat-button-toggle-group [(ngModel)]="selectedTab">
            <mat-button-toggle *ngFor="let option of tabOptions" [value]="option.value"> {{option.name}}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="m-0 details-div" *ngIf="selectedTab === 'cardInfo'">

        <div class="col-12 mt-3 stats-table">
            <div class="row m-2 stats-tile row-grap-20">
                <div class="col-lg-4 col-md-6 col-sm-6 col-6 data_grid">
                    <h5 class="title">Card Status</h5>
                    <h5 class="title-text">{{cardDetails?.cardstatus || '-'}}</h5>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 col-6 data_grid cardPrice"
                    *ngIf="cardDetails?.cardstatus == 'For Sale'">
                    <h5 class="title">Listing Price</h5>
                    <h5 class="title-text" *ngIf="cardDetails?.listingprice">{{cardDetails?.listingprice |
                        currency:'USD':'symbol'}}</h5>
                    <h5 class="title-text" *ngIf="!cardDetails?.listingprice">-</h5>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-6 data_grid" *ngIf="cardDetails?.grader != 'Ungraded'">
                    <h5 class="title">Overall Grade</h5>
                    <h5 class="title-text">{{cardDetails?.overallgrade || '-'}}</h5>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 data_grid">
                    <h5 class="title">NextGem Unique ID</h5>
                    <div class="id-div">
                        <img class="clickable" src="assets/images/icon-copy.png" title="copy"
                            (click)="copyInput(cardDetails?.cuid, 'id');">
                        <span class="copiedDiv" *ngIf="copyAction">Copied</span>
                        <h5 class="title-text">{{cardDetails?.cuid || '-'}}</h5>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 col-6 data_grid">
                    <h5 class="title">Team</h5>
                    <h5 class="title-text">{{cardDetails?.team || '-'}}</h5>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-6 data_grid" *ngIf="cardDetails?.autographed">
                    <h5 class="title">Autograph Authenticator</h5>
                    <h5 class="title-text">{{cardDetails?.authentication || '-'}}</h5>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-6 data_grid" *ngIf="cardDetails?.autographed">
                    <h5 class="title">Signed By</h5>
                    <h5 class="title-text">{{cardDetails?.signedby || '-'}}</h5>
                </div>
                <div class="card-story col-12 data_grid">
                    <h5 class="title">Card Story</h5>
                    <h5 class="title-text">{{cardDetails?.story || '-'}}</h5>
                </div>
                <div class="card-story col-12 data_grid" *ngIf="_userDetails?.userid === cardDetails?.userid">
                    <h5 class="title">Condition <span class="chip-rounded">
                            PRIVATE
                        </span></h5>
                    <h5 class="title-text" *ngFor="let condition of showDescriptionAsArray(cardDetails?.description)">
                        {{condition}}</h5>
                </div>
                <div class="tags-wrapper col-12 data_grid">
                    <h5 class="title">Tags</h5>
                    <div class="tag-list">
                        <span class="badge badge-pill tagBadge" *ngFor="let tag of cardDetails?.tags;">{{tag}}</span>
                    </div>
                    <h5 class="title-text" *ngIf="cardDetails?.tags?.length == 0">-</h5>
                </div>
            </div>
        </div>
    </div>
    <div class="m-0 details-div" *ngIf="selectedTab === 'trade'">
        <div class="trade-title">
            <h5 class="title">Owner Info</h5>
        </div>
        <div class="trade-content-wrapper">
            <div class="trade-content">
                <div class="card-owner-details">
                    <div class="d-flex align-items-center">
                        <div class="card-owner">
                            <img class="profile-log-image" src="{{getProfileImg(cardDetails?.userid)}}"
                                onerror="this.src='/assets/images/userProfile.svg'" />
                        </div>
                        <p class="user-name">{{cardDetails?.username}}</p>
                    </div>
                    <p class="member-since">Member since {{cardDetails?.registerdate}}</p>
                </div>
                <div class="go-to-profile">
                    <ng-container *ngIf="_userDetails?.userid === cardDetails?.userid; else otherUser">
                        <a [routerLink]="['/profile']" class="pointer">Go to
                            profile > </a>
                    </ng-container>

                    <ng-template #otherUser>
                        <a [routerLink]="['/profile',cardDetails?.username, cardDetails?.userid]" class="pointer">Go to
                            profile > </a>
                    </ng-template>

                </div>
            </div>
            <!-- <div class="trade-content">
                <div class="reach-out-wrapper">
                    <div class="reach-out-owner">
                        <p class="reach-out">Reach out to the owner
                        </p>
                        <p class="reach-out negotiate">Hi! Are you willing to negotiate? <img
                                src="../../../../assets/images/down-arrow.svg" />
                        </p>
                    </div>
                    <div class="reach-out-buttons">
                        <button class="btn-outline" mat-stroked-button color="primary">Go to chat</button>
                        <button class="btn-contained" mat-flat-button color="primary">Send</button>
                    </div>
                </div>
            </div> -->
            <!-- <div class="footer-buttons-wrapper">
                <button mat-flat-button>
                    Compare Prices On <img src="/assets/images/ebay.svg" alt="">
                </button>
                <button (click)="buyNow()" mat-flat-button color="primary" *ngIf="isBuyNowEnabled()">Buy Now</button>
            </div> -->


        </div>

    </div>
    <div class="m-0 details-div" *ngIf="selectedTab === 'insights'">
        <div class="col-12 mt-3 population-report"
            *ngIf="cardDetails?.grader == 'PSA' && cardDetails?.qrurl && _userDetails?.userid === cardDetails?.userid">
            <a href="{{cardDetails?.qrurl}}" target="_blank">
                <span>Population Report</span>
                <span>
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </span>
            </a>
        </div>
        <div class="col-12 mt-3 stats-table">
            <div class="row m-2 stats-tile ">
                <div class="col-lg-4 col-md-6 col-6 data_grid">
                    <h5 class="title">Genre</h5>
                    <p class="title-text">{{cardDetails?.genre || '-'}}</p>
                </div>
                <div class="col-lg-4 col-md-6 col-6 data_grid">
                    <h5 class="title">League</h5>
                    <p class="title-text">{{cardDetails?.league || '-'}}</p>
                </div>
                <div class="col-lg-4 col-md-6 col-6 data_grid">
                    <h5 class="title">Player</h5>
                    <p class="title-text">{{cardDetails?.playername || '-'}}</p>
                </div>
            </div>
            <div class="row m-2 stats-tile ">
                <div class="col-lg-4 col-md-6 col-sm-12 data_grid">
                    <h5 class="title">Serial Number</h5>
                    <p class="title-text">{{cardDetails?.serialno || '-'}}</p>
                </div>

                <div class="col-lg-4 col-md-6 col-6 data_grid">
                    <h5 class="title">Autographed</h5>
                    <p class="title-text">{{cardDetails?.autographed ? 'Yes' : 'No'}}
                    </p>
                </div>
                <div class="col-lg-4 col-md-6 col-12 data_grid">
                    <h5 class="title">
                        <mat-slide-toggle *ngIf="isOwnCard" class="primary-toggle-label" color="primary"
                            [checked]="checked" (change)="onCertificationToggle($event)" labelPosition="before">
                            Certification #
                        </mat-slide-toggle>
                        <span *ngIf="!isOwnCard">
                            Certification #
                        </span>
                    </h5>
                    <p class="title-text" *ngIf="isOwnCard || cardDetails?.obfuscation">{{cardDetails?.certno || '-'}}
                    </p>
                </div>

            </div>
            <div class="row m-2 stats-tile ">
                <div class="col-lg-4 col-md-6 col-sm-12 data_grid">
                    <h5 class="title">Card Era</h5>
                    <p class="title-text">{{cardDetails?.era || '-'}}</p>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12 data_grid">
                    <h5 class="title">Card Material</h5>
                    <p class="title-text">{{cardDetails?.material || '-'}}</p>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 data_grid">
                    <h5 class="title">Card Highlights</h5>
                    <p class="title-text" *ngFor="let hList of cardDetails?.cardhighlights; let i = index">{{i+1 + '. '
                        + hList}}</p>
                    <p class="title-text" *ngIf="cardDetails?.cardhighlights.length == 0">-</p>
                </div>
            </div>
            <div class="row m-2 stats-tile">
                <div class="col-lg-4 col-md-6 col-6 data_grid">
                    <h5 class="title">Grader</h5>
                    <p class="title-text">{{cardDetails?.grader || '-'}}</p>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 data_grid" *ngIf="cardDetails?.grader != 'Ungraded'">
                    <h5 class="title">Overall Grade</h5>
                    <h5 class="title-text">{{cardDetails?.overallgrade || '-'}}</h5>
                </div>
            </div>
            <div class="row m-2 stats-tile">

                <div class="col-12 data_grid" *ngIf="cardDetails?.grader == 'BGS'">
                    <h5 class="title">Sub-Grades</h5>
                    <table class="grade-table" width="100%">
                        <tr>
                            <td class="grade-value">{{cardDetails?.centering || '-'}}</td>
                            <td class="grade-value">{{cardDetails?.corners || '-'}}</td>
                            <td class="grade-value">{{cardDetails?.edges || '-'}}</td>
                            <td class="grade-value">{{cardDetails?.surface || '-'}}</td>
                        </tr>
                        <tr>
                            <td class="grade-label">Centering</td>
                            <td class="grade-label">Corners</td>
                            <td class="grade-label">Edges</td>
                            <td class="grade-label">Surface</td>
                        </tr>

                    </table>
                </div>
            </div>
        </div>
    </div>
</div>