export abstract class AppConstants {
    public static readonly ACCESS_TOKEN = 'accessToken';
    public static readonly REFRESH_TOKEN = 'refreshToken';
    public static readonly IS_APP_LOGGED_IN = 'is_app_logged_in';
}

export abstract class HttpStatusCode {
    public static readonly UN_AUTHORIZED_ACCESS = 401;
    public static readonly FOR_BIDDEN = 403;
    public static readonly CONFLICTS = 409;
}
export const tabOption = [
    { name: 'Card Info', value: 'cardInfo' },
    // { name: 'Trade', value: 'trade' },
    { name: 'Insights', value: 'insights' },
];
