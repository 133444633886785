<div class="collImgDiv">
    <div [ngClass]="featured ? 'featured-card-container' : '' ">
        <div class="featured-card" *ngIf="featured">
            <mat-icon aria-hidden="false" aria-label="featured-card-icon" fontIcon="star" style="color: gold;">star
            </mat-icon>
            <h6 class="featured-title">Featured Card</h6>
            <mat-icon aria-hidden="false" aria-label="featured-card-icon" fontIcon="star" style="color: gold;">star
            </mat-icon>
        </div>
        <div *ngIf="!featured" class="extra-padding"></div>
        <a [href]="card?.urlusername +'/' + card.urltitle +'/'+card.cuid"> 
        <!-- <div (click)="openCardModalHandler()"> -->
            <img *ngIf="card?.frontimagesthumb"
                [src]="imageUrl + card.cardid + card.frontimagesthumb.perspectivecorrectedthumbnail"
                [alt]="card.title" loading="lazy" class="slider-img-collection clickable collCard"> <!-- (click)="seluserProfile(card.cardid)" -->
            <div class="statColl">
                <img class="hypeImgColl" src="assets/images/icon-hype-coll.png">
                <div class="hypeValueColl">{{card.hypes || '0'}}</div>
                <div class="watchValueColl">{{card.watches || '0'}}</div>
                <img class="watchImgColl" src="assets/images/icon-watchlist-outline.png">
            </div>
            <h6 class="name">{{card.playername}}</h6>
            <h6 class="details">{{card.year}}<span *ngIf="card?.setname && card?.setname != ''">,</span>
                {{card.setname.length > 10 ? card.setname.slice(0, 10) + '...' : card.setname}}</h6>
            <div class="priceInfo" *ngIf="card.cardstatus">
                <div class="cardstatus">
                    <div class="heading">Card Status</div>
                    <div>{{card.cardstatus}}</div>
                </div>
                <div class="cardprice" *ngIf="card.cardstatus == 'For Sale'">
                    <div class="heading">Listing Price</div>
                    <div class="cardprice">{{card.listingprice | currency:'USD':'symbol'}}</div>
                </div>
            </div>
        <!-- </div> -->
        </a>
        <div>
            <!-- TODO: Put check for verified seller here once back-end is sorted  -->
            <!-- <button mat-button *ngIf="card.cardstatus == 'For Sale'" (click)="buyThisCardNow()"
                style="display: block; width: 85%; background-color: #00d8ff; color: #14111f; margin: 0 auto;">
                Buy Now
            </button> -->
        </div>
    </div>
</div>