import { Component, OnInit, Output, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FilterService } from '@approot/core/Services/filter.service';
import { SearchData } from '@approot/models/search-data';
import { CardService } from '../core/Services/card-service';

@Component({
    selector: 'app-filter-modal',
    templateUrl: './filter-modal.component.html',
    styleUrls: ['./filter-modal.component.scss']
})
export class FilterModalComponent implements OnInit {
    genres!: any[];
    allGradeOptions!: any[];
    graderOptions!: any[];
    currentYear = new Date().getFullYear();
    showAllGradeOptions = false;
    minPrice!: number | null;
    maxPrice!: number | null;
    priceInputError: boolean = false;
    yearInputError: boolean = false;

    constructor(private dialogRef: MatDialogRef<FilterModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        public cardService: CardService,
        public filterService: FilterService,
        private route: ActivatedRoute,
        private router: Router) {
        // this is data being passed into filter modal
        this.genres = data.genres;
        this.allGradeOptions = data.allGradeOptions;
        this.graderOptions = data.graderOptions;
        this.minPrice = this.filterService.selectedFilters.minPrice;
        this.maxPrice = this.filterService.selectedFilters.maxPrice;
    }


    close() {
        this.dialogRef.close();
    }

    resetFilters() {
        // console.log('reset filters');
        this.filterService.resetAllFilters();
        this.priceInputError = false;
        this.yearInputError = false;
        this.filterService.selectedSortOption = this.filterService.defaultSortOption;  //the default
        this.cardService.searchCollection();
        // this.router.navigate([], { queryParams: {} });
        this.updateQueryParams();
        this.close();
    }

    filterCardCollection() {
        // TODO: Constructing these search params is a good candidate for refactoring
        // We need it here and in profile-view for sorting
        /*
            Intermittent searching bug is happening somewhere around here
            The filter modal doesn't close when it happens so I think it happens in updateQueryParams or searchCollection
            Possibly when constructing the search params
        */
        const searchParams: SearchData = {
            keyword: "",
            estimatedprice: {
                max: this.filterService.selectedFilters.maxPrice,
                min: this.filterService.selectedFilters.minPrice
            },
            sort: this.filterService.selectedSortOption?.value || '6',
            pagesize: "2000",
            filter: {
                genres: [...this.filterService.selectedFilters.genres],
                playernames: [],
                setnames: [],
                overallgrades: this.filterService.selectedFilters.overallgrades as string[],
                graders: this.filterService.selectedFilters.graders,
                years: this.generateYearsArray(this.filterService.selectedFilters.minYear as number, this.filterService.selectedFilters.maxYear as number),
                tags: [],
                cardstatus: ""
            },
            cardtype: "true"
        };
        // console.log(searchParams);
        this.updateQueryParams();
        this.cardService.searchCollection(searchParams);
        this.close();
    }

    updateQueryParams() {
        let qp: any = {};
        if (this.filterService.selectedFilters.genres.length) {
            qp.genres = this.filterService.selectedFilters.genres.join();
        }
        if (this.filterService.selectedFilters.overallgrades.length) {
            qp.overallgrades = this.filterService.selectedFilters.overallgrades.join();
        }
        if (this.filterService.selectedFilters.graders.length) {
            qp.graders = this.filterService.selectedFilters.graders.join();
        }
        if (this.filterService.selectedFilters.minPrice || this.filterService.selectedFilters.maxPrice) {
            let min = this.filterService.selectedFilters.minPrice;
            let max = this.filterService.selectedFilters.maxPrice;
            if (!min) {
                min = 0;
            }
            if (!max) {
                max = Infinity;
            }
            qp.pricerange = max === Infinity ? `$${min}-${max}` : `$${min}-$${max}`;
        }
        if (this.filterService.selectedFilters.minYear || this.filterService.selectedFilters.maxYear) {
            // @ts-ignore
            const yearsArr = this.generateYearsArray(this.filterService.selectedFilters.minYear, this.filterService.selectedFilters.maxYear);
            qp.years = yearsArr.join();
        }
        console.log(this.filterService.selectedSortOption);
        qp.sort = this.filterService.selectedSortOption.short;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: qp,
            queryParamsHandling: '',
        });
    }

    generateYearsArray(min: number, max: number): string[] {
        // TIL that null in javascript evaluates to 0 in a mathematical expression
        // such as 2000 > null // true
        if (!min) {
            min = 1800;
        }
        if (!max) {
            max = this.currentYear;
        }
        if (max <= min) {
            return []; // if any invalid inputs, return an empty array so the filter request won't fail
        }
        const years: string[] = [];
        let counter = min;
        while (counter <= max) {
            years.push(counter.toString());
            counter++;
        }
        return years;
    }

    toggleGenreSelection(title: string) {
        if (this.isGenreSelected(title)) {
            const index = this.filterService.selectedFilters.genres.findIndex((item: any) => item === title);
            this.filterService.selectedFilters.genres.splice(index, 1);
        } else {
            this.filterService.selectedFilters.genres.push(title);
        }
        this.filterService.setActiveFilterCount();
    }

    isGenreSelected(title: string): boolean {
        return this.filterService.selectedFilters.genres.includes(title);
    }

    setMinPrice(event: any) {
        this.priceInputError = false; // clear error state on any new input
        const price = Number(event.target.value);
        if (price && price > 0) {
            this.filterService.setMinPrice(price);
            const maxPrice = this.filterService.selectedFilters.maxPrice;
            if (maxPrice && price >= maxPrice) {
                this.priceInputError = true;
            }
        }
    }

    setMaxPrice(event: any) {
        this.priceInputError = false;
        const price = Number(event.target.value);
        if (price && price > 0) { // do we want an upper limit on card value?
            this.filterService.setMaxPrice(price);
            const minPrice = this.filterService.selectedFilters.minPrice;
            if (minPrice && price <= minPrice) {
                this.priceInputError = true;
            }
        }
    }

    /**
     * Manually sets checkboxes to checked
     * Used when search params are passed in via URL
     */
    setGradeCheckboxes() {
        this.filterService.selectedFilters.overallgrades.forEach((grade: any) => {
            const gradeOption = this.allGradeOptions.find((gradeOption: any) => gradeOption.id == grade);
            gradeOption.checked = true;
        });
        // handling that irksome '7.5 and under' checkbox here
        const underGrades = [7.5, 7, 6.5, 6, 5.5, 5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1];
        const underBool = underGrades.every((grade: number) => { // looking at each checkbox 7.5 => 1
            const gradeOption = this.allGradeOptions.find((gradeOption: any) => gradeOption.id == grade);
            return gradeOption.checked;
        });
        if (underBool) { // if all are checked, check the '7.5 and under' box
            const gradeOption = this.allGradeOptions.find((gradeOption: any) => gradeOption.id == '7.5under');
            gradeOption.checked = true;
        }
    }

    /**
     * Controls whether to show the short version of the grade options
     * or all of them 10 - 1
     * Also flips each checkbox to unchecked
     */
    toggleAllGradeOptions() {
        this.filterService.selectedFilters.overallgrades = [];
        this.showAllGradeOptions = !this.showAllGradeOptions;
        this.allGradeOptions = this.allGradeOptions.map((grade: any) => {
            grade.checked = false;
            return grade;
        });
    }

    /**
     * Controls which grade checkboxes are selected in the filter modal
     * @param event click event from UI
     */
    toggleGradeSelection(event: any) {
        const id = event.source.id;
        const value = event.source._checked;
        if (id == '7.5under') { // handle 7.5 and under checkbox
            const underGrades = [7.5, 7, 6.5, 6, 5.5, 5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1];
            if (value) { // checkbox is checked
                for (let grade of underGrades) {
                    // these two statements control the checkbox UI
                    const gradeObj = this.allGradeOptions.find((gradeOption: any) => gradeOption.grade == grade);
                    if (gradeObj) {
                        gradeObj.checked = true;
                    }
                    // this adds the selected grade to the filters
                    this.filterService.selectedFilters.overallgrades.push(grade as number);
                }
            } else { // checkbox is unchecked
                for (let grade of underGrades) {
                    // these two statements control the checkbox UI
                    const gradeObj = this.allGradeOptions.find((gradeOption: any) => gradeOption.grade == grade);
                    if (gradeObj) {
                        gradeObj.checked = false;
                    }
                    // this removes the selected grade from the filters
                    const index = this.filterService.selectedFilters.overallgrades.findIndex((item: any) => item === grade);
                    this.filterService.selectedFilters.overallgrades.splice(index, 1);
                }
            }
        } else { // handle any single value
            if (value) {
                this.filterService.selectedFilters.overallgrades.push(id);
            } else {
                const index = this.filterService.selectedFilters.overallgrades.findIndex((item: any) => item === id);
                this.filterService.selectedFilters.overallgrades.splice(index, 1);
            }
        }
        this.filterService.setActiveFilterCount();
    }

    setGradeClass(grade: string | number): boolean {
        switch (grade) {
            case 10:
                return true;
            case 9.5:
                return true;
            case 9:
                return true;
            case 8.5:
                return true;
            case 8:
                return true;
            case '7.5under':
                return !this.showAllGradeOptions;
            default: // handles all remaining grades
                return this.showAllGradeOptions;
        }
    }

    toggleGraderSelection(event: any) {
        const id = event.source.id;
        const value = event.source._checked;
        if (value) { // checkbox is checked
            this.filterService.selectedFilters.graders.push(id);
        } else { // unchecked
            const index = this.filterService.selectedFilters.graders.findIndex((item: any) => item === id);
            this.filterService.selectedFilters.graders.splice(index, 1);
        }
        this.filterService.setActiveFilterCount();
    }

    /**
     * Manually sets checkboxes to checked
     * Used when search params are passed in via URL
     */
    setGraderCheckboxes() {
        this.filterService.selectedFilters.graders.forEach((grader: any) => {
            const graderOption = this.graderOptions.find((graderOption: any) => graderOption.name == grader);
            graderOption.checked = true;
        });
    }

    setMinYear(event: any) {
        this.yearInputError = false; // clear error state on any new input
        const year = Number(event.target.value);
        if (year && year > 1800 && year <= this.currentYear) {
            this.filterService.setMinYear(year);
            const maxYear = this.filterService.selectedFilters.maxYear;
            if (maxYear && year >= maxYear) {
                this.yearInputError = true;
            }
        }

    }

    setMaxYear(event: any) {
        this.yearInputError = false;
        const year = Number(event.target.value);
        if (year && year > 1800 && year <= this.currentYear) {
            this.filterService.setMaxYear(year);
            const minYear = this.filterService.selectedFilters.minYear;
            if (minYear && year <= minYear) {
                this.yearInputError = true;
            }
        }
    }

    ngOnInit(): void { // called each time the modal opens
        this.setGradeCheckboxes();
        this.setGraderCheckboxes();
    }

}
