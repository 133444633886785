import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";
import { AuthenticationService } from "../lib/authentication.service";
import { LocalStorageService } from "../lib/local-storage.service";

import { environment } from '../../../environments';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private refreshingInProgress: boolean = false;
    private accessTokenSubject: BehaviorSubject<string | any> = new BehaviorSubject<string | any>(null);

    constructor(
        private localStorageService: LocalStorageService,
        private _authService: AuthenticationService,
        private _authenticationService: AuthenticationService,
    ) { }

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (httpRequest.method == "OPTIONS") return next.handle(httpRequest);
        const NGaccessToken = environment.webToken || "%!ng4cc355T0k3n@123%!^123590abcd";
        const headers = { "NGaccessToken": `${NGaccessToken}`, "x-nextgem-webtoken": `${NGaccessToken}` };
        return next.handle(httpRequest.clone({ setHeaders: headers }));
    }
}
