<div *ngIf="this.cardService.pageLoaded">    
    <div class="profile-sidebar"> <!-- #filternav -->
        <app-user-profile [userProfile]="userService.userProfile" [collectionLength]="this.cardService.collectionList.length"></app-user-profile>
    </div>
    <div class="content">
        <div class="row responsive">
            <div class="filter-sort-button">
                <button mat-stroked-button (click)="openFilterModal()" 
                    style="background-color: #14111f; color: #00d8ff; border: 1px solid white;">
                    <mat-icon *ngIf="!this.filterService.activeFilterCount; else filterCountIcon">filter_list</mat-icon>
                    <ng-template #filterCountIcon>
                        <mat-icon>{{ 'filter_' + this.filterService.activeFilterCount}}</mat-icon>
                    </ng-template>
                    Filters
                </button>
            </div>
            <div class="filter-sort-button">
                <button mat-stroked-button [matMenuTriggerFor]="menu"
                    style="background-color: #14111f; color: #00d8ff; border: 1px solid white;">
                    <mat-icon>swap_vert</mat-icon>
                    {{ filterService.selectedSortOption ? filterService.selectedSortOption.name : 'Sort Cards' }}
                 </button>
                <mat-menu #menu="matMenu">
                    <ng-container *ngFor="let option of filterService.sortList">
                        <button mat-menu-item (click)="sortProfileCards(option)">{{ option.name }}</button>
                    </ng-container>
                </mat-menu>
                <button mat-stroked-button (click)="resetFiltersAndSort()"
                    class="reset-filter-button" *ngIf="this.filterService.activeFilterCount"
                    style="background-color: #14111f; color: #00d8ff; border: 1px solid white;">
                    <mat-icon>restart_alt</mat-icon>
                    Reset Filters
                 </button>
            </div>
            <div class="hideMdOrSmaller" style="flex-grow: 1;"></div>
            
            <form [formGroup]="cardService.searchForm" (ngSubmit)="cardService.executeUserSearch($event)">
                
                <div class="search-input-container">
                    <mat-form-field class="search-input" appearance="outline">
                        <mat-label>Search all cards</mat-label>
                        <mat-icon matPrefix>search</mat-icon>
                        <input name="search" type="search" matInput formControlName="keyword" autofocus
                            value="{{cardService.userSearch}}" (keyup.enter)="cardService.executeUserSearch($event)" />
                        <button *ngIf="cardService.userSearch" type="button" matSuffix mat-icon-button (click)="cardService.clearUserSearch()">
                            <mat-icon matSuffix>cancel</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div class="filter-sort-button">
                    <button mat-stroked-button type="submit" (click)="cardService.executeUserSearch($event)" 
                        style="background-color: #14111f; color: #00d8ff; border: 1px solid white;">
                        Search
                    </button>
                </div>
            </form>


            <div class="hideMdOrSmaller" style="flex-grow: 1;"></div>
        </div>
        <mat-tab-group mat-align-tabs="center" dynamicHeight [(selectedIndex)]="selectedTab">
            <div class="little-padding"></div>
            <mat-tab label="View All"> 
                <!-- USER SEARCH BUT NO RESULTS FOUND  -->
                <div *ngIf="(cardService.userSearch && !cardService.filteredCards.length) || 
                    (this.cardService.pageLoaded && !cardService.collectionList.length); else allCollectionCards">
                    <app-no-results></app-no-results>
                </div>
                <ng-template #allCollectionCards>
                    <!-- IF THE USER HAS INPUT A SEARCH, THIS TEMPLATE WILL SHOW  -->
                    <div class="row responsive" *ngIf="cardService.filteredCards.length; else collectionCards">
                        <ng-container *ngFor="let card of cardService.filteredCards; let i=index">
                            <!-- TODO: Update featured card here once back-end is sorted  -->
                            <app-card-listing [card]="card" [featured]="i === -1"></app-card-listing>
                        </ng-container>
                    </div>
                    <!-- OTHERWISE, IF NO SEARCH EXISTS, THIS TEMPLATE WILL SHOW ALL COLLECTION CARDS -->
                    <ng-template #collectionCards>
                        <div class="row responsive" *ngIf="cardService.collectionList.length">
                            <ng-container *ngFor="let card of cardService.collectionList; let i=index">
                                <app-card-listing [card]="card" [featured]="i === -1"></app-card-listing>
                            </ng-container>
                        </div>
                    </ng-template>
                </ng-template>
            </mat-tab>
            <!-- FOR SALE QUICK FILTER  -->
            <mat-tab label="For Sale">
                <div *ngIf="cardService.userSearch && !cardService.filteredForSaleList.length; else allForSaleCards">
                    <app-no-results></app-no-results>
                </div>
                <ng-template #allForSaleCards>
                    <div class="row responsive" *ngIf="cardService.filteredForSaleList.length; else forSaleCards">
                        <ng-container *ngFor="let card of cardService.filteredForSaleList; let i=index">
                            <app-card-listing [card]="card" [featured]="i === -1"></app-card-listing>
                        </ng-container>
                    </div>
                    <ng-template #forSaleCards>
                        <div class="row responsive" *ngIf="cardService.forSaleList.length; else noCards">
                            <ng-container *ngFor="let card of cardService.forSaleList; let i=index">
                                <app-card-listing [card]="card" [featured]="i === -1"></app-card-listing>
                            </ng-container>
                        </div>
                        <ng-template #noCards>
                            <div>
                                <app-no-results></app-no-results>
                            </div>
                        </ng-template>
                    </ng-template>
                </ng-template>
            </mat-tab>
            <!-- OPEN TO OFFERS QUICK FILTER  -->
            <mat-tab label="Open To Offers">
                <div *ngIf="cardService.userSearch && !cardService.filteredOpenToOffersList.length; else allOpenToOfferCards">
                    <app-no-results></app-no-results>
                </div>
                <ng-template #allOpenToOfferCards>
                    <div class="row responsive" *ngIf="cardService.filteredOpenToOffersList.length; else openToOfferCards">
                        <ng-container *ngFor="let card of cardService.filteredOpenToOffersList; let i=index">
                            <app-card-listing [card]="card" [featured]="i === -1"></app-card-listing>
                        </ng-container>
                    </div>
                    <ng-template #openToOfferCards>
                        <div class="row responsive" *ngIf="cardService.openToOffersList.length; else noCards">
                            <ng-container *ngFor="let card of cardService.openToOffersList; let i=index">
                                <app-card-listing [card]="card" [featured]="i === -1"></app-card-listing>
                            </ng-container>
                        </div>
                        <ng-template #noCards>
                            <div>
                                <app-no-results></app-no-results>
                            </div>
                        </ng-template>
                    </ng-template>
                </ng-template>
            </mat-tab>
            <!-- PERSONAL COLLECTION QUICK FILTER  -->
            <mat-tab label="Personal Collection">
                <div *ngIf="cardService.userSearch && !cardService.filteredPersonalCollectionList.length; else allPersonalCollectionCards">
                    <app-no-results></app-no-results>
                </div>
                <ng-template #allPersonalCollectionCards>
                    <div class="row responsive" *ngIf="cardService.filteredPersonalCollectionList.length; else personalCollectionCards">
                        <ng-container *ngFor="let card of cardService.filteredPersonalCollectionList; let i=index">
                            <app-card-listing [card]="card" [featured]="i === -1"></app-card-listing>
                        </ng-container>
                    </div>
                    <ng-template #personalCollectionCards>
                        <div class="row responsive" *ngIf="cardService.personalCollectionList.length; else noCards">
                            <ng-container *ngFor="let card of cardService.personalCollectionList; let i=index">
                                <app-card-listing [card]="card" [featured]="i === -1"></app-card-listing>
                            </ng-container>
                        </div>
                        <ng-template #noCards>
                            <div>
                                <app-no-results></app-no-results>
                            </div>
                        </ng-template>
                    </ng-template>
                </ng-template>
            </mat-tab>
        </mat-tab-group>  
    </div>
</div>


