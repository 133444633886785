import { HttpClient } from '@angular/common/http';
// import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CardAttributesType } from '@nextgem/shared-types';
import { CardService } from '../core/Services/card-service';
import { SeoService } from '../core/Services/seo-service';
import { environment } from '../../environments';
import { Title, Meta } from '@angular/platform-browser';
import { parseI18nMeta } from '@angular/compiler/src/render3/view/i18n/meta';
import { appDomainName } from '@approot/core/lib/utils';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogModel, ConfirmDialogComponent } from '@approot/confirm-modal/confirm-dialog.component';
import { LocalStorageService } from '@approot/core/lib/local-storage.service';
// import * as CryptoJS from 'crypto-js';
import * as Cookies from 'js-cookie';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
    selector: 'app-card-view',
    templateUrl: './card-view.component.html',
    styleUrls: ['./card-view.component.scss'],
    animations: [],
})
export class CardViewComponent implements OnInit {
    cardDetails: any = <CardAttributesType>{};
    defaultCard: any = { cardid: '', a: '' };
    collectionList: any = [];
    pageLoaded: boolean = false;
    @ViewChild('mblwidgetsContent', { static: false })
    mblwidgetsContent: ElementRef | any;
    totalPages: any;
    currentPage: number = 0;
    eventTypeFromChild = '';
    @ViewChild('scrollContainer') scrollContainer!: ElementRef;
    constructor(
        private cardService: CardService,
        private route: ActivatedRoute,
        private titleService: Title,
        private meta: Meta,
        public seoService: SeoService,
        private http: HttpClient,
        public dialog: MatDialog,
        private localStorageService: LocalStorageService,
        public _snackBar: MatSnackBar,
    ) {}
    @HostListener('scroll', ['$event'])
    onScroll(event: Event) {
        const element = this.scrollContainer.nativeElement;
        // Check if the user has scrolled to the right end
        console.log(element);

        if (Math.ceil(element.scrollLeft) + element.offsetWidth >= element.scrollWidth) {
            // Perform pagination or any other action
            if (!this.hideShowMore) {
                this.eventFromChild('load_more');
            }
        }
    }
    get hideShowMore() {
        const totalPages = isNaN(this.cardDetails?.totalPages)
            ? this.cardDetails?.totalPages
            : Number(this.cardDetails?.totalPages);
        const totalCardsLength = this.cardDetails?.cardList?.length;
        return totalPages == totalCardsLength || totalCardsLength > totalPages;
    }
    ngOnInit(): void {
        // First get the parameter from the current route.
        let queryParams: any = this.route.snapshot.queryParams;
        let params: any = this.route.snapshot.params;

        if (params && params.username && params.username != '' && params.cuid && params.cuid != '') {
            console.log('Nextgem Card Details via cuid: ' + params.username + ' - ' + params.cuid);
            this.titleService.setTitle('NextGem Card Details');
            this.getPageDetails('card', params.cuid);
        } else if (params && params?.cardid && params?.cardid !== '') {
            this.titleService.setTitle('NextGem Card Details');
            this.getPageDetailsByCardId(params?.cardid);
        } else if (params && params.username && params.username != '') {
            console.log('Nextgem Card Collection via username: ' + params.username);
            this.titleService.setTitle('NextGem Card Collection');
            this.getPageDetails('user', params.username);
        } else if (queryParams && queryParams.c && queryParams.c != '') {
            this.titleService.setTitle('NextGem Card Details');
            this.getPageDetails('enc', queryParams.c);
        } else if (queryParams && queryParams.u && queryParams.u != '') {
            this.titleService.setTitle('NextGem Card Collection');
            this.getPageDetails('enc', queryParams.u);
        } else {
            window.location.href = '/nodata';
        }
    }

    // aesEncrypt(payload: string) {

    //     const AES_URL_ALGORITHM = environment.aesUrlAlgorithm || 'aes-256-cbc';
    //     const AES_URL_KEY = environment.aesUrlKey || 'n3#tg3m!@!3nc$y3(pt3d&*!^39j3yq1';
    //     const AES_URL_IV = environment.aesUrlIv || 'n#$eF%x$Yt$UGFem';

    //     // const cipher = crypto.createCipheriv(AES_URL_ALGORITHM, AES_URL_KEY, AES_URL_IV);
    //     // let encrypted = cipher.update(JSON.stringify(payload), 'hex', 'utf8') + cipher.final('utf8');
    //     // reqParams = JSON.parse(decrypted);
    //     return encrypted;
    // }

    setPageSettings() {
        // presentImageIndx
        // backOpt
        // sharePopup
        // slideLeftArrow
        // slideRightArrow
        // mblSlideLeftArrow
        // mblSlideRightArrow
        // shareURL
        // title
        // collectionImageURL
        // imageURL
        // expandImages
        // images
        // thumbnails
        // mblTab
        // selectedCardID
        this.cardDetails.presentImageIndx = 0;
        this.cardDetails.backOpt = false;
        this.cardDetails.sharePopup = false;
        this.cardDetails.slideLeftArrow = false;
        this.cardDetails.slideRightArrow = false;
        this.cardDetails.mblSlideLeftArrow = false;
        this.cardDetails.mblSlideRightArrow = false;
        this.cardDetails.mblTab = true;
        this.cardDetails.shareURL = window.location.href;
        this.cardDetails.collectionImageURL = `${environment.imageURL}/`;
        this.cardDetails.selectedCardID = this.cardDetails.cardid;
        this.cardDetails.imageURL = this.cardDetails.collectionImageURL.concat(this.cardDetails.cardid);
        this.cardDetails.title =
            this.cardDetails.playername + ' ' + this.cardDetails.year + ' ' + this.cardDetails.setname;
        this.cardDetails.urltitle = this.cardDetails.title
            .replace(/\s+/g, '-')
            .replace(/null/g, '')
            .replace(/-+/g, '-');
        this.cardDetails.urltitle = encodeURIComponent(this.cardDetails.urltitle.replace(/-$/g, ''));
        this.cardDetails.urlusername = encodeURIComponent(this.cardDetails.username);

        if (this.cardDetails.variation && this.cardDetails.variation != '') {
            this.cardDetails.title = this.cardDetails.title + ' ' + this.cardDetails.variation;
        }

        if (this.cardDetails.cardnumber && this.cardDetails.cardnumber != '') {
            this.cardDetails.title = this.cardDetails.title + ' #' + this.cardDetails.cardnumber;
        }
        this.titleService.setTitle(this.cardDetails.title);
        this.meta.updateTag({
            property: 'og:title',
            content: this.cardDetails.title,
        });
        const frontimagesthumb = this.cardDetails.frontimagesthumb;
        this.meta.updateTag({
            property: 'og:image',
            content: this.cardDetails.imageURL.concat(this.cardDetails.frontimages.cropped),
        });
        this.seoService.createLinkForCanonicalURL();
        const frontimages = this.cardDetails.frontimages;
        const backimagesthumb = this.cardDetails.backimagesthumb;
        const backimages = this.cardDetails.backimages;

        this.cardDetails.expandImages = [];
        this.cardDetails.images = [];
        const isSubprofileUpload = this.cardDetails?.imagesourcetype === 1 ? true : false;

        let images = [];
        let expandImages = [];
        // if (frontimages && backimages) {

        // placeholder image
        this.cardDetails['placeholderImage'] = '/assets/images/card-placeholder.png';
        const slabPath = '/assets/images/back-slab.png';
        const rawPath = '/assets/images/back-raw.png';
        const placeholderPath = this.cardDetails?.cardtype === 'slab' ? slabPath : rawPath;
        this.cardDetails['sendOfferPlaceholderImage'] =
            this.cardDetails?.frontbackimage === 1 ? placeholderPath : '/assets/images/card-placeholder.png';
        if (!isSubprofileUpload) {
            expandImages.push(frontimages?.cropped);
            expandImages.push(backimages?.cropped);

            expandImages.push(frontimages?.topleft);
            expandImages.push(frontimages?.topright);
            expandImages.push(frontimages?.bottomleft);
            expandImages.push(frontimages?.bottomright);
            expandImages.push(backimages?.topleft);
            expandImages.push(backimages?.topright);
            expandImages.push(backimages?.bottomleft);
            expandImages.push(backimages?.bottomright);
        } else {
            expandImages.push(frontimages?.perspectivelow);
            expandImages.push(backimages?.perspectivelow);
        }
        this.cardDetails.expandImages = expandImages.filter(x => x);
        images.push(frontimages?.perspectivelow);
        images.push(backimages?.perspectivelow);
        if (!isSubprofileUpload) {
            images.push(frontimages?.topleft);
            images.push(frontimages?.topright);
            images.push(frontimages?.bottomleft);
            images.push(frontimages?.bottomright);
            images.push(backimages?.topleft);
            images.push(backimages?.topright);
            images.push(backimages?.bottomleft);
            images.push(backimages?.bottomright);
        }
        this.cardDetails.images = images.filter(x => x);
        //         }

        if (this.cardDetails.images.length >= 5) {
            this.cardDetails.slideRightArrow = true;
            this.cardDetails.mblSlideRightArrow = true;
        }

        this.cardDetails.thumbnails = [];
        let thumbnails = [];
        // if (frontimagesthumb && backimagesthumb) {
        thumbnails.push(frontimagesthumb?.perspectivecorrectedthumbnail);
        thumbnails.push(backimagesthumb?.perspectivecorrectedthumbnail);
        if (!isSubprofileUpload) {
            thumbnails.push(frontimagesthumb?.topleftthumbnail);
            thumbnails.push(frontimagesthumb?.toprightthumbnail);
            thumbnails.push(frontimagesthumb?.bottomleftthumbnail);
            thumbnails.push(frontimagesthumb?.bottomrightthumbnail);
            thumbnails.push(backimagesthumb?.topleftthumbnail);
            thumbnails.push(backimagesthumb?.toprightthumbnail);
            thumbnails.push(backimagesthumb?.bottomleftthumbnail);
            thumbnails.push(backimagesthumb?.bottomrightthumbnail);
        } else {
            // console.log(this.cardDetails, 'this.cardDetails');

            // if (this.cardDetails?.frontbackimage === 1) {
            //     const placeholderPath = '/assets/images/card-placeholder.png';
            //     thumbnails.push(placeholderPath);
            //     // console.log(thumbnails);
            // }

            if (this.cardDetails?.frontbackimage === 1) {
                const placeholderPath =
                    environment.imageURL + this.cardDetails?.cardtype === 'slab' ? 'back-slab.png' : 'back-raw.png';
                thumbnails.push(placeholderPath);
                // console.log(thumbnails);
            }
        }
        // }
        this.cardDetails.thumbnails = thumbnails.filter(x => x);
        this.pageLoaded = true;
        // console.log(this.cardDetails);
    }
    get mainImageURL(): string {
        return this.cardDetails?.imageURL + this.cardDetails?.images[this.cardDetails?.presentImageIndx];
    }
    onImgError(event: any) {
        event.target.src = this.cardDetails['sendOfferPlaceholderImage'];
    }

    setCardDetails() {
        if (this.cardDetails.tags == null) {
            this.cardDetails.tags = [];
        } else {
            this.cardDetails.tags = Object.keys(this.cardDetails.tags);
        }

        if (this.cardDetails.overallgrade) {
            this.cardDetails.overallgrade = parseFloat(this.cardDetails.overallgrade);
        }

        if (this.cardDetails.cardhighlights == null) {
            this.cardDetails.cardhighlights = [];
        } else {
            this.cardDetails.cardhighlights = this.cardDetails.cardhighlights.split(',');
            this.cardDetails.cardhighlights = this.cardDetails.cardhighlights.filter(function (el: any) {
                return el != '';
            });
        }

        this.setPageSettings();
    }
    async getPageDetailsByCardId(cardid?: string) {
        let urlStructure: any = environment.cardDetailsURL + cardid;
        const options: any = { params: { p: 'mc' } };
        const cardDetails: any = await this.cardService.get(urlStructure, options).toPromise();
        this.getPageDetails('card', cardDetails?.data?.cuid);
    }
    getPageDetails(type: string, c: string) {
        let urlStructure: any = environment.cardDetailsURL + 'web/' + type + '/' + c;
        // console.log(urlStructure);

        if (c == this.defaultCard.cardid) {
            urlStructure = urlStructure.concat('*' + this.defaultCard.a);
        }

        this.cardService.get(urlStructure).subscribe(
            (res: any) => {
                if (Object.keys(res.data.cardData).length > 0) {
                    this.cardDetails = res.data.cardData as CardAttributesType;
                    this.setCardDetails();

                    if (res.data.defaultCardID != '' && res.data.defaultCardofs != '') {
                        this.defaultCard.cardid = res.data.defaultCardID;
                        this.defaultCard.a = res.data.defaultCardofs;
                    }
                }
                this.cardDetails.cardList = [];
                const cardstatus = String(this.cardDetails?.cardstatus).toLowerCase();
                const isEligibleStatusForMakeOffer = cardstatus == 'open to offers' || cardstatus == 'for sale';
                this.cardDetails['showSendOfferButton'] = isEligibleStatusForMakeOffer;
                // this.getUsersCollection();
                // const _collectionList = res.data.collectionList;
                // if (_collectionList.length > 0) {
                //     for (let card of _collectionList) {
                //         card.urlplayername = card.playername.replace(/\s+/g, '-');
                //         if (!card.title) {
                //             card.title = card.playername + ' ' + card.year + ' ' + card.setname + ' ' + card.variation;
                //         }
                //         card.urltitle = card.title.replace(/\s+/g, '-').replace(/null/g, '').replace(/-+/g, '-');
                //         card.urltitle = encodeURIComponent(card.urltitle.replace(/-$/g, ''));
                //         card.urlusername = encodeURIComponent(card.username);
                //     }
                //     this.collectionList = _collectionList;
                //     this.cardDetails.cardList = _collectionList;
                // } else {
                //     this.cardDetails.cardList = this.collectionList;
                // }
                this.getSimilarCards(this.cardDetails?.cardid);
            },
            err => {
                console.log('*********Page Details API*********');
                console.log(err.status);
            },
        );
    }
    getSimilarCards(cardid?: string) {
        const payload = {
            cardid: cardid || this.cardDetails?.cardid,
            pagesize: 20,
            page: this.currentPage + 1,
            // playername,
        };
        this.http.post(`${environment.cardServiceDomain}/get/similar/cards`, payload).subscribe(
            (res: any) => {
                this.cardDetails['similarCards'] = res?.data || [];
            },
            err => {},
        );
    }
    getUsersCollection(isFromPagination = false) {
        const cardList = this.cardDetails.cardList;
        const payload = {
            userid: this.cardDetails?.userid,
            pagesize: 20,
            page: this.currentPage,
            keyword: '',
        };
        this.http.post(`${environment.cardServiceDomain}/discoversearch`, payload).subscribe(
            (res: any) => {
                let data: any[] = res?.data?.cards || [];
                // this.collectionList = data;
                if (!isFromPagination) {
                    this.totalPages = res?.data?.totalcount;
                    this.cardDetails['totalPages'] = this.totalPages;
                }

                if (data.length > 0) {
                    for (let card of data) {
                        card.urlplayername = card.playername.replace(/\s+/g, '-');
                        if (!card.title) {
                            card.title = card.playername + ' ' + card.year + ' ' + card.setname + ' ' + card.variation;
                        }
                        card.urltitle = card.title.replace(/\s+/g, '-').replace(/null/g, '').replace(/-+/g, '-');
                        card.urltitle = encodeURIComponent(card.urltitle.replace(/-$/g, ''));
                        card.urlusername = encodeURIComponent(card.username);
                    }
                    this.cardDetails.cardList = [...cardList, ...data];
                }
            },
            err => {},
        );
    }
    setCard(cid: any) {
        // on clicking a card in collection list this function is executed
        this.cardDetails.selectedCardID = cid;
        this.getPageDetails('cd', cid);
    }

    backToCollection(uid: any) {
        window.location.href = '/view?uid=' + uid;
    }

    copyInputMessage(value: any) {
        var el: any = document.createElement('textarea');
        el.value = value;
        el.setAttribute('readonly', '');
        el.style = { position: 'absolute', left: '-9999px' };
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        const description = `Link Copied`;
        this._snackBar.open(description, undefined, {
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            duration: 5000,
            panelClass: 'custom-snackbar',
        });
    }
    eventFromChild(event: any) {
        this.eventTypeFromChild = event;
        switch (event) {
            case 'load_more':
                this.currentPage += 1;
                console.log('event captured');
                // this.getUsersCollection(true);
                break;
            case 'send_offer':
            case 'buy_now':
                this.openLoginModal();
                break;
            default:
                break;
        }
    }
    mblScrollLeft() {
        this.mblwidgetsContent.nativeElement.scrollLeft -= 200;
        this.mblThumbailScroll();
    }

    mblScrollRight() {
        this.mblwidgetsContent.nativeElement.scrollLeft += 200;
        this.mblThumbailScroll();
    }

    mblThumbailScroll() {
        let offsetWidth = this.mblwidgetsContent.nativeElement.offsetWidth;
        let scrollLeft = this.mblwidgetsContent.nativeElement.scrollLeft;
        let scrollWidth = this.mblwidgetsContent.nativeElement.scrollWidth;

        if (scrollLeft == 0) {
            this.cardDetails.mblSlideLeftArrow = false;
            this.cardDetails.mblSlideRightArrow = true;
        } else if (offsetWidth + scrollLeft == scrollWidth) {
            this.cardDetails.mblSlideLeftArrow = true;
            this.cardDetails.mblSlideRightArrow = false;
        } else {
            this.cardDetails.mblSlideLeftArrow = true;
            this.cardDetails.mblSlideRightArrow = true;
        }
    }

    mblViewImage(tagIndx: any) {
        let sliderTag = document.getElementsByClassName('mbl slider-img');
        for (var i: any = 0; i < sliderTag.length; i++) {
            sliderTag[i].classList.remove('card-active');
            if (i == tagIndx) {
                sliderTag[i].classList.add('card-active');
            }
        }

        this.cardDetails.presentImageIndx = tagIndx;
    }

    appShare() {
        try {
            navigator.share({
                title: this.cardDetails.title,
                text: this.cardDetails.deeplinkurl,
                url: this.cardDetails.deeplinkurl,
            });
        } catch (err) {
            console.log(err);
            this.copyInputMessage(this.cardDetails.deeplinkurl);
        }
    }
    get cookiesDomain() {
        const isLocalhost = Boolean(
            window.location.hostname === 'localhost' ||
                // [::1] is the IPv6 localhost address.
                window.location.hostname === '[::1]' ||
                // 127.0.0.1/8 is considered localhost for IPv4.
                window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
        );
        const DOMAIN_NAME = environment.domainName || 'nextgem.com';
        const domain = isLocalhost ? 'localhost' : DOMAIN_NAME;
        return domain;
    }
    openLoginModal() {
        const domainName = appDomainName();

        const message = `Login or Sign-up to complete this step.`;

        const dialogData = new ConfirmDialogModel(' ', message);
        console.log('**** called modal ****');
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: dialogData,
            panelClass: 'confirm-modal',
            backdropClass: 'dialog-bg-trans',
            // disableClose: true,
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult) {
                const sessionExpirationTime = 1000 * 60;
                const expirationDate = new Date(new Date().getTime() + sessionExpirationTime * 1000);
                Cookies.set('event_triggered_from_cards', this.eventTypeFromChild, {
                    domain: this.cookiesDomain,
                    expires: expirationDate,
                });
            }
            const pathName = `cards/${this.cardDetails?.cardid}`;
            const encodedPathName = encodeURIComponent(pathName);
            if (dialogResult === 'login') {
                const urlWithQueryParam =
                    pathName !== '/' ? `${domainName}/login?path=${encodedPathName}` : `${domainName}/login`;
                window.location.href = urlWithQueryParam;
            } else if (dialogResult === 'signup') {
                const urlWithQueryParam =
                    pathName !== '/'
                        ? `${domainName}/signup-landing?path=${encodedPathName}`
                        : `${domainName}/signup-landing`;
                window.location.href = urlWithQueryParam;
            }
        });
    }
}
