import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TooltipComponent } from '@angular/material/tooltip';
import { environment } from '../../environments';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  constructor() { }

  @Input() userProfile = {
    profileimage: '',
    username: '',
    followers: [],
    following: [],
    fullname: '',
    bio: '',
    socialmedia: []
  };

  @Input() collectionLength = 0;
  @ViewChild('matTooltip') tooltip: any;

  ngOnInit(): void {
    
  }

  async copyShareProfleURL () {
    const url = window.location.href;
    console.log(url);
    try {
      await navigator.clipboard.writeText(url);
    } catch (error) {
      throw error;
    }
    
  }

  socialIcon: any = {
    1: 'icon-twitter',
    2: 'icon-instagram',
    3: 'icon-youtube',
    4: 'icon-ebay',
    5: 'icon-myslabs',
    6: 'icon-facebook'
  };

  socialBaseURL: any = {
    1: 'https://twitter.com/',
    2: 'https://www.instagram.com/',
    3: 'https://www.youtube.com/results?search_query=',
    4: 'https://www.ebay.com/usr/',
    5: 'https://www.myslabs.com/browse/account/?owner=',
    6: 'https://www.facebook.com/'
  };
  

}
