<ng-container *ngIf="pageLoaded">

    <div class="d-block d-md-none card-details-mobile">
        <div class="row card-details d-block d-md-none">
            <div class="col-12 col-sm-12 col-md-4 mbl-card-section">
                <div class="card nextgem-card">
                    <img [src]="mainImageURL" (error)="onImgError($event)" class="maxWidth">
                </div>
                <div class="d-flex justify-content-center">
                    <div class="d-flex mbl-nextgem-card-thumb">
                        <div class="mbl-custom-slider-arrow mbl-ml-n15">
                            <span class="slider-btn">
                                <img src="assets/images/left-arrow.svg" alt="" class="clickable"
                                    (click)="mblScrollLeft()" *ngIf="cardDetails?.mblSlideLeftArrow">
                                <img src="assets/images/left-arrow-disabled.svg" alt=""
                                    *ngIf="!cardDetails?.mblSlideLeftArrow">
                            </span>
                        </div>
                        <div #mblwidgetsContent class="mbl-custom-slider-main" (scroll)="mblThumbailScroll()">
                            <ng-container>
                                <img *ngFor="let image of cardDetails?.thumbnails; let i = index;"
                                    src="{{cardDetails?.imageURL + image}}" (error)="onImgError($event)"
                                    class="mbl slider-img clickable"
                                    [ngClass]="{'card-active' : i==cardDetails.presentImageIndx}"
                                    (click)="mblViewImage(i)">
                            </ng-container>
                        </div>
                        <div class="mbl-custom-slider-arrow mbl-mr-n15">
                            <span class="slider-btn">
                                <img src="assets/images/right-arrow.svg" alt="" class="clickable"
                                    (click)="mblScrollRight()" *ngIf="cardDetails?.mblSlideRightArrow">
                                <img src="assets/images/right-arrow-disabled.svg" alt=""
                                    *ngIf="!cardDetails?.mblSlideRightArrow">
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="statCollmbl">
                <div class="hypeImgCollmbl">
                    <img src="assets/images/icon-hype-outline-1.png">
                </div>
                <div class="hypeValueCollmbl">{{cardDetails?.hypes || '0'}}</div>

                <div class="watchImgCollmbl">
                    <img src="assets/images/icon-watchlist-outline-1.png">
                </div>
                <div class="watchValueCollmbl">{{cardDetails?.watches || '0'}}</div>

                <div class="shareImgCollmbl clickable" (click)="appShare()">
                    <img src="assets/images/icon-share-icon.png">
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-8 card-details_right">
                <app-card-details-view [cardDetails]="cardDetails" (copyMessage)="copyInputMessage($event)"
                    (eventFromChild)="eventFromChild($event)">
                </app-card-details-view>
            </div>
        </div>
        <!-- Card Collection Mobile -->
        <div class="mbl-collection-section" *ngIf="cardDetails?.similarCards?.length > 0 && cardDetails?.username">
            <h4 class="mbl-collection-heading desk-mycollection-head">More like this card</h4>
            <div class="d-flex">
                <div class="mbl-slider-collection" *ngIf="cardDetails?.similarCards" #scrollContainer
                    (scroll)="onScroll($event)">
                    <ng-container *ngFor="let card of cardDetails?.similarCards;">
                        <div class="collImgDiv">
                            <img [src]="cardDetails?.collectionImageURL + card?.cardid + card?.frontimage"
                                class="slider-img-collection clickable collCard" (click)="setCard(card.cardid)"
                                [ngClass]="{'card-active' : card.cardid==cardDetails?.selectedCardID}">

                            <div class="statColl">
                                <div class="hypeImgColl">
                                    <img src="assets/images/icon-hype-coll.png">
                                </div>
                                <div class="hypeValueColl">{{card?.hypes || '0'}}</div>
                                <img class="watchImgColl" src="assets/images/icon-watchlist-outline.png">
                            </div>

                            <h6 class="name">{{card?.playername}}</h6>
                            <h6 class="details">{{card?.year}}<span
                                    *ngIf="card?.setname && card?.setname != ''">,</span>
                                {{card?.setname}}</h6>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <!-- Card Collection Mobile -->
    </div>
    <div class="d-none d-md-block">
        <div class="edit-card-detials"
            *ngIf="userId === cardDetails?.userid && cardDetails?.cardstatus !== 'Sales Pending'"><button
                mat-stroked-button color="primary" (click)="onEditCardClicked()"> <img
                    src="../../../assets/images/edit-icon.svg" alt="edit-card-details" />Edit
                Card Details</button>
        </div>
        <app-card-view-web [cardDetails]="cardDetails" (eventFromChild)="eventFromChild($event)"
            (setCard)="setCard($event)" (copyInputMessage)="copyInputMessage($event)"></app-card-view-web>
    </div>
</ng-container>