import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CardViewComponent } from './card-view/card-view.component';
import { UserProfileViewComponent } from './profile/profile-view.component';
import { SearchComponent } from './search/search.component';
import { EmailUploadComponent } from './email-upload/email-upload.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { BrowserModule, Title } from '@angular/platform-browser';

const routes: Routes = [
    {
        path: 'view',
        component: CardViewComponent,
        pathMatch: 'full',
    },
    {
        path: 'search',
        component: SearchComponent,
        pathMatch: 'full',
    },
    // {
    //     path: 'emailuploads',
    //     component: EmailUploadComponent,
    //     pathMatch: 'full'
    // },
    {
        path: ':username',
        component: UserProfileViewComponent,
        pathMatch: 'full',
    },
    {
        path: 'profile/:username/:userid',
        component: UserProfileViewComponent,
        pathMatch: 'full',
    },
    {
        path: ':username/:cardid',
        component: CardViewComponent,
        pathMatch: 'full',
    },
    {
        path: ':username/:googletext/:cuid',
        component: CardViewComponent,
        pathMatch: 'full',
    },
    {
        path: '404',
        component: NotFoundComponent,
    },
    // {
    //     path: '**',
    //     // redirectTo: search
    //     component: SearchComponent,
    // },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'search',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [Title],
})
export class AppRoutingModule {}
