// import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { CardAttributesType, UserAttributes } from '@nextgem/shared-types';
import { CardService } from '../core/Services/card-service';
import { UserService } from '../core/Services/user-service';
import { SeoService } from '../core/Services/seo-service';
import { environment } from '../../environments';
import { Title, Meta } from '@angular/platform-browser';
import { HttpHeaders } from '@angular/common/http';
import { parseI18nMeta } from '@angular/compiler/src/render3/view/i18n/meta';
import { FormArray } from '@angular/forms';
import { CardDetails } from '../models/card-details'; // interface for card data
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FilterService } from '@approot/core/Services/filter.service';

import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInput } from '@angular/material/chips';
import { FilterModalComponent } from '../filter-modal/filter-modal.component';
import { SearchData } from '@approot/models/search-data';


// import * as CryptoJS from 'crypto-js';

@Component({
    selector: 'app-profile-view',
    templateUrl: './profile-view.component.html',
    styleUrls: ['./profile-view.component.scss'],
    providers: [FilterModalComponent]
})
export class UserProfileViewComponent implements OnInit {
    showSort: boolean = false;
    showFilter: boolean = false;
    showPrice: boolean = true;
    searchForm: FormGroup;

    uniqSetnames: any = [];
    uniqPlayernames: any = [];
    uniqGenres: any = [];
    uniqGrades: any = [];
    uniqGraders: any = [];
    uniqYears: any = [];
    uniqTags: any = [];
    uniqCardstatus: any = [];
    minPrice: number = 0;
    maxPrice: number = 0;
    selectedTab: number = 0; // Controls the active Quick Filter, 0 is View All

    profileCards: any = [];
    imageUrl: any = environment.imageURL + '/';

    selectedFilters: any;
    cardCount: any = {};
    activeFilters: { value: string, filter: string; name: string; }[] = [];

    tagList: any = [
        // { name: 'On Card Auto', value: 'On Card Auto' },
        // { name: 'Sticker Auto', value: 'Label / Sticker Auto' },
        // { name: 'Cut Signiature', value: 'Cut Signiature Auto' },
        { name: 'Autograph', value: 'Autograph' },
        { name: 'Base Set', value: 'Base Set' },
        { name: 'Insert', value: 'Insert' },
        { name: 'Memorabilia', value: 'Memorabilia' },
        { name: 'Multi-Player', value: 'Multi-Player' },
        { name: 'Parellel', value: 'Parellel' },
        { name: 'Parellel Insert', value: 'Parellel Insert' },
        { name: 'Printing Plate', value: 'Printing Plate' },
        { name: 'Rookie', value: 'Rookie' },
        { name: 'Serial#', value: 'Serial#' },
        { name: 'Short-Print', value: 'Short-Print' },
    ];

    graderList: any = [
        { name: 'BGS', value: 'BGS' },
        { name: 'PSA', value: 'PSA' },
        { name: 'SGC', value: 'SGC' },
        { name: 'Other', value: 'Other' },
        { name: 'Ungraded', value: 'Ungraded' }
    ];

    cardstatusList: any = [
        { name: 'Personal Collection', value: 'Personal Collection' },
        { name: 'Open to Offers', value: 'Open to Offers' },
        { name: 'For Sale', value: 'For Sale' }
    ];

    constructor(public cardService: CardService,
        public userService: UserService,
        public filterService: FilterService,
        public seoService: SeoService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private meta: Meta,
        private dialog: MatDialog) {
        this.searchForm = this.fb.group({
            keyword: [''],
            // sort: ['6'],
            // pagesize: ['200'],
        });
    }

    async ngOnInit(): Promise<void> {
        // if there are params in the URL, this grabs them and sends them to the API
        let queryParams: any = this.route.snapshot.queryParams;
        // set sorting
        if (queryParams.sort) {
            this.filterService.setSelectedSortOption(queryParams.sort);
        } else {
            // this.setDefaultSortParam();
            console.log("default sort");
        }

        let minPrice: number | null = null;
        let maxPrice: number | null = null;
        if (queryParams.pricerange) {
            minPrice = queryParams.pricerange.split('-')[0].replace('$', '');
            maxPrice = queryParams.pricerange.split('-')[1].replace('$', '');
        }


        // construct search params to send to API
        const searchParams: SearchData = {
            keyword: "",
            estimatedprice: {
                max: maxPrice,
                min: minPrice
            },
            sort: this.filterService.selectedSortOption.value,
            pagesize: "2000",
            filter: {
                genres: queryParams?.genres?.split(',') || [],
                playernames: [],
                setnames: [],
                overallgrades: queryParams?.overallgrades?.split(',') || [],
                graders: queryParams?.graders?.split(',') || [],
                years: queryParams?.years?.split(',') || [],
                tags: [],
                cardstatus: ""
            },
            cardtype: "true"
        };

        // Update UI in FilterModal
        if (Object.keys(queryParams).length) {
            this.filterService.updateUIFromSearchParams(searchParams);
            if (queryParams?.keyword) {
                this.cardService.userSearch = queryParams?.keyword;
            }
        }

        let params: any = this.route.snapshot.params;
        if (params && params.username && params.username != "") {
            // console.log("NextGem User Profile" + params.username);
            await this.userService.getProfile(params.username);
            this.titleService.setTitle(params.username + " NextGem Profile");

            this.meta.updateTag({
                property: 'og:title', content: params.username + " NextGem Profile"
            });

            // getProfile() above has to resolve before we can call searchCollection()
            // despite all of my efforts (resolving a promise, async/await, etc) -
            // angular was still calling searchCollection() before the user ID was loaded
            // so we have this soooper hacky solution that makes everything work
            setTimeout(() => {
                if (Object.keys(queryParams).length) {
                    this.cardService.searchCollection(searchParams);
                } else {
                    this.cardService.searchCollection();
                }
                this.createLinkForCanonicalURL();
            }, 1000);
        }

    }

    createLinkForCanonicalURL() {
        this.seoService.createLinkForCanonicalURL();
    }
    setDefaultSortParam() {
        let qp: any = {};
        qp.sort = this.filterService.selectedSortOption.short;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: qp,
            queryParamsHandling: '',
        });
    }

    cardCountGreaterThanZero(status: string): boolean {
        const statusCards = this.cardService.collectionList.filter((card: CardDetails) => card.cardstatus === status);
        return statusCards.length > 0;
    }

    onlyUnique(value: any, index: any, self: any) {
        return self.indexOf(value) === index;
    }

    openFilterModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
            genres: this.filterService.genreList,
            allGradeOptions: this.filterService.allGradeOptions,
            graderOptions: this.filterService.graderOptions,
            selectedFilters: this.selectedFilters,
        };
        if (window.innerWidth < 600) { // only need to set height here on phone size
            dialogConfig.height = window.innerHeight * .8 + 'px';
        }

        this.dialog.open(FilterModalComponent, dialogConfig);
    }

    generateYearsArray(min: number, max: number): string[] {
        const currentYear = new Date().getFullYear();
        if (!min) {
            min = 1800;
        }
        if (!max) {
            max = currentYear;
        }
        if (max <= min) {
            return []; // if any invalid inputs, return an empty array so the filter request won't fail
        }
        const years: string[] = [];
        let counter = min;
        while (counter <= max) {
            years.push(counter.toString());
            counter++;
        }
        return years;
    }

    sortProfileCards(option: any) {
        // console.log('sort profile cards ', option);
        this.filterService.selectedSortOption = option;
        // grab filter options from filter service
        // TODO: Constructing these search params is a good candidate for refactoring
        // We need it here and in filter-modal for sorting
        const searchParams: SearchData = {
            keyword: "",
            estimatedprice: {
                max: this.filterService.selectedFilters.maxPrice,
                min: this.filterService.selectedFilters.minPrice
            },
            sort: option.value,
            pagesize: "2000",
            filter: {
                genres: [...this.filterService.selectedFilters.genres],
                playernames: [],
                setnames: [],
                overallgrades: this.filterService.selectedFilters.overallgrades as string[],
                graders: this.filterService.selectedFilters.graders,
                years: this.generateYearsArray(this.filterService.selectedFilters.minYear as number, this.filterService.selectedFilters.maxYear as number),
                tags: [],
                cardstatus: ""
            },
            cardtype: "true"
        };

        // construct new params and call search collection from card service
        const queryParams: any = this.route.snapshot.queryParams;
        const newParams = {
            ...queryParams,
            sort: option.short
        };

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: newParams,
            queryParamsHandling: '',
        });
        this.cardService.searchCollection(searchParams);
    }

    resetFiltersAndSort(): void {
        let qp: any = {};
        this.cardService.userSearch = '';
        this.selectedTab = 0;
        this.filterService.resetAllFilters();
        this.filterService.selectedSortOption = this.filterService.defaultSortOption;
        this.cardService.searchCollection();
        qp.sort = this.filterService.selectedSortOption.short;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: qp,
            queryParamsHandling: '',
        });
    }

};
