import { LocalStorageService } from './core/lib/local-storage.service';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as Cookies from 'js-cookie';
import { environment } from 'src/environments';
import { ConfirmDialogModel, ConfirmDialogComponent } from './confirm-modal/confirm-dialog.component';
import { AppConstants } from './core/lib/constants/common-constants';
import { appDomainName } from './core/lib/utils';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'nextgem-webview';
    disableContent = false;
    constructor(public dialog: MatDialog, private localStorageService: LocalStorageService) {
        const idToken = Cookies.get(AppConstants.IS_APP_LOGGED_IN);
        console.log(idToken, '**** idToken ****');

        const _this = this;
        console.log(_this.domainName, '**** domain + path ****', location.pathname);
        if (idToken) {
            // let link_ref = String(window.location.href);
            const new_ref = _this.domainName + window.location.pathname + window.location.search;
            console.log(new_ref, 'link_ref');

            window.location.href = new_ref;
        }
        setTimeout(() => {
            console.log('**** after 30secs ****', idToken);

            if (!idToken) {
                console.log('**** called after 30 sec ****');

                _this.openLoginModal();
                // _this.disableContent = true;
            }
        }, 30000);
    }
    get domainName() {
        return appDomainName();
        // const isLocalhost = Boolean(
        //     window.location.hostname === 'localhost' ||
        //         // [::1] is the IPv6 localhost address.
        //         window.location.hostname === '[::1]' ||
        //         // 127.0.0.1/8 is considered localhost for IPv4.
        //         window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
        // );
        // const subDomainSuffix = environment.deploymentType === 'PRODUCTION' ? '' : `-${environment.environment}`;
        // const webReduxWebsiteDomainName = `app${subDomainSuffix}.${environment.domainName || 'nextgem.com'}`;
        // const domain = isLocalhost ? 'http://localhost:4400' : `https://${webReduxWebsiteDomainName}`;
        // return domain;
    }
    get cookiesDomain() {
        const isLocalhost = Boolean(
            window.location.hostname === 'localhost' ||
                // [::1] is the IPv6 localhost address.
                window.location.hostname === '[::1]' ||
                // 127.0.0.1/8 is considered localhost for IPv4.
                window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
        );
        const DOMAIN_NAME = environment.domainName || 'nextgem.com';
        const domain = isLocalhost ? 'localhost' : DOMAIN_NAME;
        return domain;
    }
    login() {
        const pathName = location.pathname;
        const encodedPathName = encodeURIComponent(pathName);
        const urlWithQueryParam =
            pathName !== '/' ? `${this.domainName}/login?path=${encodedPathName}` : `${this.domainName}/login`;

        const sessionExpirationTime = 1000 * 60;
        const expirationDate = new Date(new Date().getTime() + sessionExpirationTime * 1000);
        Cookies.set('params', location.search, {
            domain: this.cookiesDomain,
            expires: expirationDate,
        });
        window.location.href = urlWithQueryParam;
    }

    openLoginModal() {
        const is_login_popup_shown = this.localStorageService.getItem('is_login_popup_shown');
        if (is_login_popup_shown) {
            return;
        }
        const message = `To view this card and collection in detail, please create an account.`;

        const dialogData = new ConfirmDialogModel(' ', message);
        console.log('**** called modal ****');
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: dialogData,
            panelClass: 'confirm-modal',
            backdropClass: 'dialog-bg-trans',
            // disableClose: true,
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            const sessionExpirationTime = 1000 * 60;
            const expirationDate = new Date(new Date().getTime() + sessionExpirationTime * 1000);
            Cookies.set('params', location.search, {
                domain: this.cookiesDomain,
                expires: expirationDate,
            });
            if (dialogResult === 'login') {
                const pathName = location.pathname;
                const encodedPathName = encodeURIComponent(pathName);
                const urlWithQueryParam =
                    pathName !== '/' ? `${this.domainName}/login?path=${encodedPathName}` : `${this.domainName}/login`;
                window.location.href = urlWithQueryParam;
            } else if (dialogResult === 'signup') {
                const urlWithQueryParam = `${this.domainName}/signup-landing`;
                window.location.href = urlWithQueryParam;
            } else {
                this.localStorageService.setItem('is_login_popup_shown', 'true');
            }
        });
    }
}
