import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CardAttributesType, UserAttributes } from '@nextgem/shared-types';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments';
import { CardDetails } from '@approot/models/card-details';
import { SearchData } from '@approot/models/search-data';
import { UserService } from './user-service';

@Injectable({
    providedIn: 'root',
})
export class CardService {
    searchForm!: FormGroup;
    uniqSetnames: any = [];
    uniqPlayernames: any = [];
    uniqGenres: any = [];
    uniqGrades: any = [];
    uniqGraders: any = [];
    uniqYears: any = [];
    uniqTags: any = [];
    uniqCardstatus: any = [];
    minPrice: number = 0;
    maxPrice: number = 0;
    userSearch: string = ''; // holds keyword search from input
    selectedTab: number = 0; // 0 is View All
    profileCards: any = [];
    collectionList: CardDetails[] = [];
    filteredCards: CardDetails[] = []; // filteredCards holds a copy of collectionList and is only populated when the user searches or filters
    forSaleList: CardDetails[] = [];
    filteredForSaleList: CardDetails[] = [];
    openToOffersList: CardDetails[] = [];
    filteredOpenToOffersList: CardDetails[] = [];
    personalCollectionList: CardDetails[] = [];
    filteredPersonalCollectionList: CardDetails[] = [];
    imageUrl: any = environment.imageURL + '/';
    cardCount: any = {};
    defaultSearch: SearchData = {
        keyword: '',
        estimatedprice: {
            max: null,
            min: null,
        },
        sort: '6',
        pagesize: '2000',
        userid: '',
        filter: {
            genres: [],
            playernames: [],
            setnames: [],
            overallgrades: [],
            graders: [],
            years: [],
            tags: [],
            cardstatus: '',
        },
        cardtype: 'true',
    };

    constructor(
        private http: HttpClient,
        public userService: UserService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        this.searchForm = this.fb.group({
            keyword: [''],
        });
    }

    get<T>(url: string, options?: {}) {
        return this.http.get<T>(url, options);
    }

    post(url: string, data: any, options?: any) {
        return this.http.post(url, data, options);
    }

    updateQueryParams() {
        console.log('query params called');

        // construct new params and call search collection from card service
        const queryParams: any = this.route.snapshot.queryParams;
        console.log('Updating keyword queryparamet to ' + this.userSearch);
        const newParams = {
            ...queryParams,
            keyword: this.userSearch,
        };
        console.log(newParams);

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: newParams,
            queryParamsHandling: '',
        });
    }

    onlyUnique(value: any, index: any, self: any): boolean {
        return self.indexOf(value) === index;
    }
    pageLoaded: boolean = false;

    /**
     * This pings the API to return the card data
     * Called on profile page load
     * If search parameters are passed in, it uses those
     * Otherwise, it uses the default search params
     */
    searchCollection(searchData?: SearchData) {
        // console.log('search collection called');
        this.filteredCards = []; // reset filtered cards on any search
        if (!searchData) {
            searchData = this.defaultSearch;
        }
        console.log('search params ', searchData);

        // let keyword = encodeURI(this.keywords.value)
        let playernames: any = [];
        let setnames: any = [];
        let genres: any = [];
        let grades: any = [];
        let graders: any = [];
        let years: any = [];
        let tags: any = [];
        let cardstatus: any = [];
        let minPrice = 9999999999;
        let maxPrice = 0;
        searchData['userid'] = this.userService.userProfile.userid;
        let headers = { 'x-nextgem-userid': this.userService.userProfile.userid };
        // let headers = { 'x-nextgem-userid': '0b4c2aba-f95b-4625-903d-bd0ce042e708'};
        // let urlStructure: any = environment.cardServiceDomain + "/es/mycollection";
        let urlStructure: any = environment.cardServiceDomain + '/discoversearch';
        // this.updateQueryParams();
        console.log('url card service:', urlStructure, searchData, { headers });
        this.post(urlStructure, searchData, { headers }).subscribe(
            (res: any) => {
                // console.log('first card: ', res.data.cards[0]);
                // console.log(`cards fetched: ${res.data.cards.length}`);

                if (res.data.cards.length > 0) {
                    this.collectionList = [];
                    for (let card of res.data.cards) {
                        if (card.playername) playernames.push(card.playername);
                        if (card.setnames) setnames.push(card.setnames);
                        if (card.genre) genres.push(card.genre);
                        if (card.overallgrade) grades.push(card.overallgrade);
                        if (card.grader) graders.push(card.grader);
                        if (card.year) years.push(card.year);
                        if (card.tags) {
                            for (let tag of card.tags) {
                                tags.push(tag);
                            }
                        }
                        if (card.cardstatus) cardstatus.push(card.cardstatus);
                        if (card.listingprice && card.listingprice > maxPrice) maxPrice = card.listingprice;
                        if (card.listingprice && card.listingprice < minPrice) minPrice = card.listingprice;
                        card.urlplayername = card.playername.replace(/\s+/g, '-');
                        if (!card.title) {
                            card.title = card.playername + ' ' + card.year + ' ' + card.setname + ' ' + card.variation;
                        }
                        card.username = this.userService.userProfile.username;
                        card.urltitle = card.title.replace(/\s+/g, '-').replace(/null/g, '').replace(/-+/g, '-');
                        card.urltitle = encodeURIComponent(card.urltitle.replace(/-$/g, ''));
                        card.urlusername = encodeURIComponent(card.username);
                    }
                    // TODO: Featured Card
                    this.collectionList = res.data.cards
                        .sort((card: any) => card.featured)
                        .sort((card: any) => !card.featured);
                    // console.log(`collection list: ${this.collectionList.length}`);

                    for (let card of this.collectionList) {
                        this.profileCards.push(card);
                        this.cardCount[card.cardstatus] = this.cardCount[card.cardstatus]
                            ? this.cardCount[card.cardstatus] + 1
                            : 1;
                    }
                    this.uniqPlayernames = playernames.filter(this.onlyUnique).sort();
                    this.uniqSetnames = setnames.filter(this.onlyUnique).sort();
                    this.uniqGenres = genres.filter(this.onlyUnique).sort();
                    this.uniqGrades = grades.filter(this.onlyUnique).sort();
                    this.uniqGraders = graders.filter(this.onlyUnique).sort();
                    this.uniqYears = years.filter(this.onlyUnique).sort();
                    this.uniqTags = tags.filter(this.onlyUnique).sort();
                    this.uniqCardstatus = cardstatus.filter(this.onlyUnique).sort();
                    this.maxPrice = maxPrice;
                    this.minPrice = minPrice;

                    // set quick filter lists
                    this.forSaleList = this.collectionList.filter(
                        (card: CardDetails) => String(card.cardstatus).toLowerCase() === 'for sale',
                    );
                    this.openToOffersList = this.collectionList.filter(
                        (card: CardDetails) => String(card.cardstatus).toLowerCase() === 'open to offers',
                    );
                    this.personalCollectionList = this.collectionList.filter(
                        (card: CardDetails) => String(card.cardstatus).toLowerCase() === 'personal collection',
                    );
                    // execute user search/filter if it is present
                    if (this.userSearch) {
                        const event = { target: { value: this.userSearch } };
                        const control: FormControl = this.searchForm.get('keyword') as FormControl;
                        control.setValue(this.userSearch);
                        this.executeUserSearch(event);
                    }
                    this.pageLoaded = true;
                } else {
                    console.log('no cards returned for search');
                    this.collectionList = [];
                    this.pageLoaded = true;
                }
            },
            err => {
                console.log('*********Page Details API*********');
                console.log(err);
            },
        );
    }

    /**
     * Takes a user search and filters card collection
     * UI shows filteredCards if it has length, otherwise it shows collectionList
     * @param event user input event from text field
     * @returns void
     */
    executeUserSearch(event: any): void {
        let data = this.searchForm.value;
        console.log(data);
        if (!data) {
            console.log('no target value');
            this.userSearch = '';
            this.filteredCards = [];
            return;
        } else {
            console.log(event);
            console.log(this.userSearch);
            console.log(data);
        }

        // let keyword = data["keyword"];

        this.userSearch = data['keyword'].toLowerCase();
        if (this.selectedTab != 0) {
            this.selectedTab = 0; // move user to View All tab
        }

        this.filteredCards = this.collectionList.filter((card: any) => {
            return card.title.toLowerCase().includes(this.userSearch);
        });

        // here I'm filtering each sub-category of card to correspond to the quick filter views
        // frankly, this doesn't feel like a very elegant solution but i'm out of time
        this.filteredForSaleList = this.forSaleList.filter((card: any) => {
            return card.title.toLowerCase().includes(this.userSearch);
        });

        this.filteredOpenToOffersList = this.openToOffersList.filter((card: any) => {
            return card.title.toLowerCase().includes(this.userSearch);
        });

        this.filteredPersonalCollectionList = this.personalCollectionList.filter((card: any) => {
            return card.title.toLowerCase().includes(this.userSearch);
        });
        this.updateQueryParams();
    }

    /**
     * This clears the user search input and moves them to View All
     */
    clearUserSearch(): void {
        this.userSearch = '';
        this.filteredCards = [];
        this.filteredForSaleList = [];
        this.filteredOpenToOffersList = [];
        this.filteredPersonalCollectionList = [];
        this.selectedTab = 0;
    }
}
