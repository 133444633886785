<div *ngIf="pageLoaded">

    <form [formGroup]="searchForm" (ngSubmit)="searchCollection()">

        <mat-sidenav-container>
            <mat-sidenav #filternav mode="side">
                <div class="unscrollable">
                    <div class="row">
                        <div class="filterButton" (click)="toggleAllFilters()">Clear Filters</div>
                        <div class="filterButton" (click)="searchCollection()">Run Filters</div>
                        <!-- <div class="results">FILTERS</div> -->
                        <div class="floatright">
                            <div (click)="filternav.toggle()" class="floatright"><img class="closeicon"
                                    src="/assets/images/close-btn.png" alt="close"></div>
                        </div>
                    </div>
                    <div class="Line"></div>

                    <div *ngIf="activeFilters">
                        <div class="filterdisplay">

                            <ng-container *ngFor="let filter of activeFilters; let i=index">
                                <div class="filterButton" (click)="toggleFilters(filter.filter,filter.value)">
                                    {{filter.name}} x</div>
                            </ng-container>
                        </div>
                        <div *ngIf="!activeFilters.length > 0">
                            <div>No Filters</div>
                        </div>
                    </div>
                </div>
                <div class="Line"></div>
                <div class="scrollable">
                    <div class="filterItem" formGroupName="estimatedprice"
                        *ngIf="(maxPrice != 0 && maxPrice != minPrice)">
                        <!-- <div class="filterItem" formGroupName="estimatedprice"> {{maxPrice}} -->
                        <label class="collapsible" id="priceContent" (click)="toggle('price')"> Listing Price
                            Value:</label>
                        <div class="content" *ngIf="showPrice">
                            <label class="labelDesc" for="minPrice">Min:</label><input min="0" class="inputItem dollar"
                                (change)="toggleFilters('pricerange','')" id="minPrice" type="number"
                                formControlName="min" placeholder="{{minPrice}}">
                            <label class="labelDesc" for="maxPrice">Max:</label><input min="0" class="inputItem dollar"
                                (change)="toggleFilters('pricerange','')" id="maxPrice" type="number"
                                formControlName="max" placeholder="{{maxPrice}}">
                        </div>
                    </div>

                    <div class="filterItem" formGroupName="filter" *ngIf="uniqGenres.length > 1">
                        <label class="collapsible" id="genreContent" (click)="toggle('genre')"> Genre:</label>
                        <div class='content' formArrayName="genres" *ngIf="uniqGenres">
                            <ng-container *ngFor="let genre of uniqGenres; let i=index">
                                <input class="inputItem" type="checkbox" id={{genre}} value={{genre}}
                                    (click)="toggleFilters('genres',genre)"><label class="labelDesc"
                                    for={{genre}}>{{genre}}</label><br>
                            </ng-container>
                        </div>
                    </div>

                    <div class="filterItem" formGroupName="filter" *ngIf="uniqPlayernames.length > 1">
                        <label class="collapsible" id="nameContent" (click)="toggle('name')"> Player Name:</label>
                        <div class='content' formArrayName="playernames" *ngIf="uniqPlayernames">
                            <ng-container *ngFor="let playername of uniqPlayernames; let i=index">
                                <input class="inputItem" type="checkbox" id={{playername}} value={{playername}}
                                    (click)="toggleFilters('playernames',playername)"><label class="labelDesc"
                                    for={{playername}}>{{playername}}</label><br>
                            </ng-container>
                        </div>
                    </div>

                    <div class="filterItem" formGroupName="filter" *ngIf="uniqSetnames.length > 1">
                        <label class="collapsible" id="setContent" (click)="toggle('set')"> Set:</label>
                        <div class='content' formArrayName="setnames" *ngIf="uniqSetnames">
                            <ng-container *ngFor="let setname of uniqSetnames; let i=index">
                                <input class="inputItem" type="checkbox" id={{setname}} value={{setname}}
                                    (click)="toggleFilters('setnames',setname)"><label class="labelDesc"
                                    for={{setname}}>{{setname}}</label><br>
                            </ng-container>
                        </div>
                    </div>

                    <div class="filterItem" formGroupName="filter" *ngIf="uniqGrades.length > 1">
                        <label class="collapsible" id="gradeContent" (click)="toggle('grade')"> Grade:</label>
                        <div class='content' formArrayName="overallgrades" *ngIf="uniqGrades">
                            <ng-container *ngFor="let grade of uniqGrades; let i=index">
                                <input class="inputItem" type="checkbox" id={{grade}} value={{grade}}
                                    (click)="toggleFilters('overallgrades',grade)"><label class="labelDesc"
                                    for={{grade}}>{{grade}}</label><br>
                            </ng-container>
                        </div>
                    </div>

                    <div class="filterItem" formGroupName="filter" *ngIf="uniqGraders.length > 1">
                        <label class="collapsible" id="graderContent" (click)="toggle('grader')"> Grader:</label>
                        <div class='content' formArrayName="graders" *ngIf="graderList">
                            <ng-container *ngFor="let g of graderList; let i=index">
                                <input class="inputItem" type="checkbox" id={{g.value}} value={{g.value}}
                                    (click)="toggleFilters('graders',g.name)"><label class="labelDesc"
                                    for={{g.value}}>{{g.name}}</label><br>
                            </ng-container>
                        </div>
                    </div>

                    <div class="filterItem" formGroupName="filter" *ngIf="uniqYears.length > 1">
                        <label class="collapsible" id="yearContent" (click)="toggle('year')"> Card Year:</label>
                        <div class='content' formArrayName="years" *ngIf="uniqYears">
                            <ng-container *ngFor="let year of uniqYears; let i=index">
                                <input class="inputItem" type="checkbox" id={{year}} value={{year}}
                                    (click)="toggleFilters('years',year)"><label class="labelDesc"
                                    for={{year}}>{{year}}</label><br>
                            </ng-container>
                        </div>
                    </div>

                    <div class="filterItem" formGroupName="filter" *ngIf="uniqTags.length > 1">
                        <label class="collapsible" id="tagContent" (click)="toggle('tag')"> Tags:</label>
                        <div class='content' formArrayName="tags" *ngIf="uniqTags">
                            <ng-container *ngFor="let tag of uniqTags; let i=index">
                                <input class="inputItem" type="checkbox" id={{tag}} value={{tag}}
                                    (click)="toggleFilters('tags',tag)"><label class="labelDesc"
                                    for={{tag}}>{{tag}}</label><br>
                            </ng-container>
                        </div>
                    </div>

                    <div class="filterItem" formGroupName="filter" *ngIf="uniqCardstatus.length > 1">
                        <label class="collapsible" id="statusContent" (click)="toggle('status')"> Card Status:</label>
                        <div class='content' *ngIf="uniqCardstatus">
                            <ng-container *ngFor="let status of uniqCardstatus; let i=index">
                                <input class="inputItem" type="radio" formControlName="cardstatus" id={{status}}
                                    value={{status}} (click)="toggleFilters('cardstatus',status)"><label
                                    class="labelDesc" for={{status}}>{{status}}</label><br>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </mat-sidenav>



            <mat-sidenav-content>
                <!-- <div *ngIf="userProfile" class="profileInputs">
                    <div class="image" *ngIf="userProfile?.profileimage">
                        <img class="profile-img" [src]=userProfile.profileimage  onError="this.src='assets/images/weblogo.png';">
                    </div>
                    <div class="profile-details">         
                        <div class="profile-username" *ngIf="userProfile?.username">{{userProfile?.username}}</div>

                        <div class="break"></div>
                        <div *ngIf="userProfile?.followers" class="row">            
                            <div class="share">{{userProfile?.followers.length || '0'}} followers</div>
                            <div class="share">{{userProfile?.following.length || '0'}} following</div>

                        </div>
                        <div class="break"></div>
                        <div *ngIf="userProfile?.fullname" class="row fullname">{{userProfile?.fullname}}</div>
                        <div class="break"></div>

                        <div *ngIf="userProfile?.bio" class="row bio">{{userProfile?.bio}}</div>
                        <div class="break"></div>

                        <div class="socialmedia row" *ngIf="userProfile?.socialmedia" > 
                            <ng-container *ngFor="let media of userProfile.socialmedia; let i=index">
                                <a class="social-link" href="{{socialBaseURL[media.socialmediaid]}}{{media.username}}">                        
                                    <img class="social-img" src="assets/images/{{socialIcon[media.socialmediaid]}}.png">{{media.username}}</a>
                            </ng-container>
                        </div> 
                    </div>
                </div> -->


                <div class="Line"></div>
                <!-- <mat-tab-group mat-align-tabs="center" dynamicHeight> -->

                <!-- <mat-tab label="Search">  -->
                <div class="searchWidget row">
                    <input id="name" type="search" (input)="searchOnEmpty()" (keyup.enter)="onSubmit()"
                        formControlName="keyword" autofocus placeholder="Search cards on NextGem" align="center">
                    <button type="button" (click)="onSubmit()">Search</button>
                    <div *ngIf="search">
                        <div class="filterButton" (click)="filternav.toggle()">
                            <img src="assets/images/icon-filter.png">Filter
                        </div>
                        <div class="filterButton">
                            <img src="assets/images/icon-sort.png">
                            <select formControlName="sort" name="sort" id="sort">
                                <option *ngFor="let option of sortList" [value]="option.value">
                                    {{option.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
                <!-- <div class="debug">
                            <p>Form Value: {{ searchForm.value | json }}</p> 
                            <p>Form Status: {{ searchForm.status }}</p> 
                        </div>    -->

                <div class="searchdetails" #scrollTargetMain>
                    <div class="results" *ngIf="collectionList">{{totalPages}} results</div>
                </div>

                <div class="row responsive m-h-70vh" *ngIf="collectionList && imageUrl">
                    <ng-container *ngFor="let card of collectionList; let i=index">
                        <div class="collImgDiv">
                            <a [href]="card.urlusername +'/' + card.urltitle +'/'+card.cuid">
                                <img *ngIf="card?.frontimagesthumb"
                                    [src]="imageUrl + card?.cardid + card?.frontimagesthumb.perspectivecorrectedthumbnail"
                                    [alt]="card?.title" loading="lazy" class="slider-img-collection clickable collCard"
                                    (click)="seluserProfile(card.cardid)">
                                <div class="statColl">
                                    <img class="hypeImgColl" src="assets/images/icon-hype-coll.png">
                                    <div class="hypeValueColl">{{card?.hypes || '0'}}</div>
                                    <div class="watchValueColl">{{card?.watches || '0'}}</div>
                                    <img class="watchImgColl" src="assets/images/icon-watchlist-outline.png">

                                </div>
                                <h6 class="name">{{card?.playername}}</h6>
                                <h6 class="details">{{card?.year}}<span
                                        *ngIf="card?.setname && card?.setname != ''">,</span> {{card?.setname}}</h6>
                                <div class="priceInfo" *ngIf="card.cardstatus">
                                    <div class="cardstatus">
                                        <div class="heading">Card Status</div>
                                        <div>{{card?.cardstatus}}</div>
                                    </div>
                                    <div class="cardprice" *ngIf="card.cardstatus == 'For Sale'">
                                        <div class="heading">Listing Price</div>
                                        <div class="cardprice">{{card?.listingprice| currency:'USD':'symbol'}}</div>
                                    </div>
                                </div>

                            </a>
                        </div>
                    </ng-container>
                </div>
                <div class="page-footer" *ngIf="collectionList?.length">
                    <mat-paginator #paginator class="demo-paginator" (page)="changePage($event)" [length]="totalPages"
                        [pageIndex]="currentPage" [pageSize]="pageSize" hidePageSize="false"
                        [showFirstLastButtons]="true" [pageSizeOptions]="[20, 50, 100,200,500]" color="primary"
                        aria-label="Select page">
                    </mat-paginator>
                </div>
                <!-- </mat-tab>
                    <ng-container *ngFor="let status of cardstatusList.reverse(); let i=index">
                        <mat-tab label={{status.name}}> 
                            <div class="row responsive" *ngIf="profileCards && imageUrl">
                                <ng-container *ngFor="let card of profileCards; let i=index">
                                    <div class="collImgDiv" *ngIf="card?.cardstatus==status.name">
                                        <a [href]="card?.username +'/' + card.urlplayername +'/'+card.cuid"> 
                                            <img *ngIf="card?.frontimagesthumb" [src]="imageUrl + card?.cardid + card?.frontimagesthumb.perspectivecorrectedthumbnail" [alt]="card?.title" loading="lazy" class="slider-img-collection clickable collCard" (click)="seluserProfile(card.cardid)">
                                            <div class="statColl">           
                                                <img class="hypeImgColl"src="assets/images/icon-hype-coll.png">
                                                <div class="hypeValueColl">{{card?.hypes || '0'}}</div>
                                                <div class="watchValueColl">{{card?.watches || '0'}}</div>
                                                <img class="watchImgColl" src="assets/images/icon-watchlist-outline.png">
                                            </div>
                                            <h6 class="name">{{card?.playername}}</h6>
                                            <h6 class="details">{{card?.year}}<span *ngIf="card?.setname && card?.setname != ''">,</span> {{card?.setname}}</h6>
                                            <div class="priceInfo" *ngIf="card.cardstatus">
                                                <div class="cardstatus">
                                                    <div class="heading">Card Status</div>
                                                    <div>{{card?.cardstatus}}</div>
                                                </div>
                                                <div class="cardprice" *ngIf="card.cardstatus == 'For Sale'">
                                                    <div class="heading">Listing Price</div>
                                                    <div class="cardprice">{{card?.listingprice| currency:'USD':'symbol'}}</div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </ng-container>
                            </div>
                        </mat-tab>
                    </ng-container>
                
                </mat-tab-group>   -->
            </mat-sidenav-content>
        </mat-sidenav-container>
    </form>
</div>