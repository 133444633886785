import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CardDetails } from '@approot/models/card-details';
import { environment } from '../../environments';

@Component({
    selector: 'app-card-details-modal',
    templateUrl: './card-details-modal.component.html',
    styleUrls: ['./card-details-modal.component.scss']
})

export class CardDetailsModalComponent implements OnInit {
    card!: CardDetails;
    imageUrl: any = environment.imageURL + '/';

    constructor(private dialogRef: MatDialogRef<CardDetailsModalComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
        this.card = data.card;
    }

    ngOnInit(): void {

    }

    close() {
        console.log('close');
        this.dialogRef.close();
    }

    /**
     * Takes a string with spaces and returns the string with dashes
     * @param title 'JOSH ALLEN 2018 TOPPS ALL STARS'
     * @returns 'JOSH-ALLEN-2018-TOPPS-ALL-STARS'
     */
    urlEncode(title: string) {
        // some titles have a 'null' value and some have whitespace
        return encodeURIComponent(title.replace('null', '').trim().replace(/ /g, '-'));
    }

    async copyShareLink() {
        // not sure if there's a better way to get the URL base
        const title = this.urlEncode(this.card.title);
        const username = this.urlEncode(this.card.username);
        const link = `${location.host}/${username}/${title}/${this.card.cuid}`;
        try {
            await navigator.clipboard.writeText(link);
        } catch (error) {
            throw error;
        }
    }



}
