import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CardService } from '../Services/card-service';
import { AppConstants } from './constants/common-constants';
import { environment } from '../../../environments';
import { LocalStorageService } from './local-storage.service';


@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {

    constructor(private _router: Router, private cardService: CardService,
        private localStorageService: LocalStorageService) {

    }

    isLoggedIn() {
        const USER_TOKEN = this.localStorageService.getItem(AppConstants.ACCESS_TOKEN);
        return !!(USER_TOKEN);
    }

    logout(redirect: boolean) {
        if (redirect) {
            this._router.navigate(['login']);
        }
    }


    refreshToken(): Observable<any> {
        const REFRESH_TOKEN = this.localStorageService.getItem(AppConstants.REFRESH_TOKEN);
        const ACCESS_TOKEN = this.localStorageService.getItem(AppConstants.ACCESS_TOKEN);
        return this.cardService.post(
            environment.baseURL + `User/refresh-token`,
            { REFRESH_TOKEN, ACCESS_TOKEN })
            .pipe(
                tap((response: any) => {
                    if (response || response.hasOwnProperty('Token') || response['Token']) {
                        this.setToken(AppConstants.ACCESS_TOKEN, response['Token']);
                    }
                })
            );
    }

    private setToken(key: string, token: string): void {
        this.localStorageService.setItem(key, token);
    }

}
