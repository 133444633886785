import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
    title: string;
    message: string;
    hasConfirmText: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel,
    ) {
        // Update view with given values
        this.title = data.title;
        this.message = data.message;
    }

    ngOnInit() {}

    onConfirm(): void {
        this.dialogRef.close('login');
    }
    signup() {
        this.dialogRef.close('signup');
    }
    onClose() {
        this.dialogRef.close();
    }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {
    constructor(public title: string, public message: string) {}
}
