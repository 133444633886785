<div class="filter-modal-container">
    <h2 mat-dialog-title class="filter-header">Filters</h2>
    <mat-dialog-content>
        <h3 class="filter-title">Genre</h3>
        <div class="row">
            <div *ngFor="let genre of genres">
                <button mat-button class="genre-button" (click)="toggleGenreSelection(genre.name)"
                    [ngClass]="isGenreSelected(genre.name) ? 'selected' : 'unselected' ">
                    <mat-icon>{{ genre.icon }}</mat-icon>
                    {{ genre.name }}</button>
            </div>
        </div>
        <h3 class="filter-title">Price Range</h3>
        <div>
            <form action="">
                <mat-form-field appearance="outline" class="filter-input">
                    <mat-label>Min</mat-label>
                    <input type="number" matInput placeholder="0" value="{{minPrice}}"
                        (input)="setMinPrice($event)">
                </mat-form-field>
                <mat-form-field appearance="outline" class="filter-input">
                    <mat-label>Max</mat-label>
                    <input type="number" matInput placeholder="0" value="{{maxPrice}}"
                        (input)="setMaxPrice($event)">
                </mat-form-field>
                <mat-error *ngIf="priceInputError">Invalid price input</mat-error>
            </form>
            
        </div>
        <h3 class="filter-title">Grade
            <button mat-icon-button (click)="toggleAllGradeOptions()">
                <mat-icon>more_horiz</mat-icon>
            </button>
        </h3>
        <div>
            <ng-container *ngFor="let grade of allGradeOptions">
                <mat-checkbox class="filter-checkbox" id='{{ grade.id }}'
                    (change)="toggleGradeSelection($event)"
                    [(ngModel)]="grade.checked" 
                    [ngClass]="setGradeClass(grade.id) ? 'show' : 'hidden' ">{{ grade.grade }}</mat-checkbox>
            </ng-container>         
     
        </div>
        <h3 class="filter-title">Grader</h3>
        <div>
            <ng-container *ngFor="let grader of graderOptions">
                <mat-checkbox class="filter-checkbox" id='{{ grader.name }}'
                    (change)="toggleGraderSelection($event)"
                    [(ngModel)]="grader.checked">
                    {{ grader.name }}
                </mat-checkbox>
            </ng-container>
        </div>
        <h3 class="filter-title">Card Year</h3>
        <div>
            <form action="">
                <mat-form-field appearance="outline" class="filter-input">
                    <mat-label>Min</mat-label>
                    <input type="number" matInput placeholder="2000"  value="{{filterService.selectedFilters.minYear}}"
                        (input)="setMinYear($event)">
                </mat-form-field>
                <mat-form-field appearance="outline" class="filter-input">
                    <mat-label>Max</mat-label>
                    <input type="number" matInput placeholder="2020"  value="{{filterService.selectedFilters.maxYear}}"
                        (input)="setMaxYear($event)">
                </mat-form-field>
                <mat-error *ngIf="yearInputError">Invalid year input</mat-error>
            </form>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div style="flex-grow: 1"></div>
        <button mat-button (click)="resetFilters()"
            class="filter-reset">Reset</button>
        <button mat-button (click)="filterCardCollection()"
            [ngClass]="priceInputError || yearInputError ? 'filter-save-disabled' : 'filter-save' " 
            disabled="{{ priceInputError || yearInputError }}">Save</button>
            <!-- <div [ngClass]="featured ? 'featured-card-container' : '' "></div> -->
    </mat-dialog-actions>
</div>
