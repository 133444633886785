<body>
    <app-loading></app-loading>
    <header>
        <div class="d-none d-md-block deskimg">
            <!-- <img src="/assets/images/weblogo.png"> -->
            <a href="https://www.nextgem.com/" target="_blank" class="linkCss d-flex"><img src="/assets/images/logo.png"
                    alt="HTML tutorial">
                <!-- <h4 class="desktop-heading">Collect with Friends</h4> -->
                <h4 class="desktop-heading">Rare Cards Within Reach</h4>
            </a>
        </div>
        <div class="d-block d-md-none mblimg">
            <!-- <img src="/assets/images/weblogo.png"> -->
            <a href="https://nextgem.com/" target="_blank">
                <img src="/assets/images/logo.png" alt="HTML tutorial" style="float: left;">
                <!-- <h4 class="mobile-heading mblleft">Rare Cards Within Reach</h4> -->
            </a>
        </div>
        <div class="d-flex d-md-none align-items-center">
            <!-- <h4 class="mobile-heading">NextGem - Collect with Friends</h4> -->
            <!-- <a href="https://form.typeform.com/to/jBmDHGPp" target="_blank" class="linkCss"><b class="mobile-heading mblright">Join the Waitlist</b></a> -->
            <!-- <a href="https://my.nextgem.com/app-invite" target="_blank" class="linkCss"> <img
                    src="/assets/images/appStore.svg"></a> -->
            <button class="btn btn-block" mat-raised-button color="accent" (click)="login()">Login</button>
            <!-- <button><b>Download</b></button> -->
        </div>
        <div class="d-none d-md-flex align-items-center">
            <h4 class="appDownloadDesk">
                <button class="btn btn-block" mat-raised-button color="accent" (click)="login()">Login</button>
            </h4>
            <a href="https://play.google.com/store/apps/details?id=com.nextgem.pwaapp" target="_blank"
                class="linkCss download-link">
                <img src="/assets/images/googleStore.png"></a>

            <a href="https://my.nextgem.com/app-invite" target="_blank" class="linkCss download-link ml-2"> <img
                    src="/assets/images/appStore.png"></a>
        </div>
    </header>
    <div class="container-fluid router-div content" id="appComponent" *ngIf="!disableContent;else dummyScreen">
        <router-outlet></router-outlet>
    </div>
    <ng-template #dummyScreen>
        <div class="container-fluid router-div content" id="appComponent">
            <img src="/assets/images/search.png" alt="" srcset="" class="dummy-img">
        </div>
    </ng-template>

    <!-- <footer class="footer">
    © Copyright NextGem 2022
  </footer> -->
</body>