<div class="row card-details">
    <div class="col-12 col-sm-12 col-md-4">
        <div class="card nextgem-card">
            <img id="myimage" *ngIf="cardDetails?.imageURL"
                src="{{cardDetails?.imageURL.concat(cardDetails?.images[cardDetails?.presentImageIndx])}}"
                (error)="onImgError($event)" (click)="openFullImage()" class="img-magnifier-container"
                (load)="magnify(8)" alt="{{cardDetails?.title}}">
        </div>
        <div class="d-flex justify-content-center">
            <div class="d-flex nextgem-card-thumb">
                <div class="custom-slider-arrow ml-n15">
                    <span class="slider-btn">
                        <img src="assets/images/left-arrow.svg" class="clickable" alt="" (click)="scrollLeft()"
                            *ngIf="cardDetails?.slideLeftArrow">
                        <img src="assets/images/left-arrow-disabled.svg" alt="" *ngIf="!cardDetails?.slideLeftArrow">
                    </span>
                </div>
                <div #widgetsContent class="custom-slider-main">
                    <ng-container>
                        <img *ngFor="let image of cardDetails?.thumbnails; let i = index;"
                            src="{{cardDetails?.imageURL.concat(image)}}" (error)="onImgError($event)"
                            class="desk slider-img clickable"
                            [ngClass]="{'card-active' : i==cardDetails.presentImageIndx}" (click)="viewImage(i)">
                    </ng-container>
                </div>
                <div class="custom-slider-arrow mr-n15">
                    <span class="slider-btn">
                        <img src="assets/images/right-arrow.svg" class="clickable" alt="" (click)="scrollRight()"
                            *ngIf="cardDetails?.slideRightArrow">
                        <img src="assets/images/right-arrow-disabled.svg" alt="" *ngIf="!cardDetails?.slideRightArrow">
                    </span>
                </div>

            </div>

        </div>
        <div class="d-flex justify-content-center mt-3">
            <div class="statCollmbl d-flex justify-content-around w-100">
                <div class="d-flex">
                    <div class="d-flex">
                        <div class="hypeImgCollmbl">
                            <img src="assets/images/icon-hype-outline-1.png">
                        </div>
                        <div class="hypeValueCollmbl">{{cardDetails?.hypes || '0'}}</div>
                    </div>
                    <div class="d-flex">
                        <div class="watchImgCollmbl">
                            <img src="assets/images/icon-watchlist-outline-1.png">
                        </div>
                        <div class="watchValueCollmbl">{{cardDetails?.watches || '0'}}</div>
                    </div>
                </div>
                <div></div>
                <div class="d-flex">

                    <div class="shareImgCollmbl clickable" (click)="copyMessage(cardDetails?.deeplinkurl)">
                        <img src="assets/images/icon-share-icon.png">
                    </div>
                </div>


            </div>
        </div>

    </div>
    <div class="expanded-img" *ngIf="isImageExpanded" (click)="openFullImage()">
        <img id="expandImage"
            src="{{cardDetails?.imageURL.concat(cardDetails?.expandImages[cardDetails?.presentImageIndx])}}"
            (error)="onImgError($event)" class="img-magnifier-container">
    </div>
    <div class="col-12 col-sm-12 col-md-8 card-details_right img-zoom-container">
        <div id="myresult" class="img-zoom-result"></div>
        <app-card-details-view [cardDetails]="cardDetails" (eventFromChild)="sendEventToparent($event)"
            (copyMessage)="copyMessage($event)"></app-card-details-view>
    </div>
    <!-- <div class="col-12 col-sm-12 col-md-8 card-details_right img-zoom-container" *ngIf="isShareClicked">
        <app-share-card [cardDetails]="cardDetails" (isShareViewEnabled)="isShareViewEnabled()"></app-share-card>
    </div> -->

</div>
<!-- Card Collection PC -->
<div class="my-collection-section" *ngIf="cardDetails?.similarCards?.length > 0 && cardDetails?.username">
    <h4 class="card-details_heading desk-mycollection-head" *ngIf="cardDetails?.username">More like this card</h4>
    <!-- <h4 class="card-details_heading desk-mycollection-head" *ngIf="!cardDetails?.username">Collection</h4> -->
    <div class="d-flex">
        <div class="custom-slider-arrow ml-n15 collectionScrollImg clickable" (click)="scrollCollectionLeft()">
            <span class="slider-btn">
                <img src="assets/images/left-arrow.svg" alt="">
            </span>
        </div>
        <div class="custom-slider-collection" id="collectionScroll" *ngIf="cardDetails?.similarCards" #scrollContainer
            (scroll)="onScroll($event)">
            <ng-container *ngFor="let card of cardDetails?.similarCards;">
                <a [href]="card?.urlusername +'/' + card.urltitle +'/'+card.cuid">
                    <div class="collImgDiv">
                        <img [src]="cardDetails?.collectionImageURL + card?.cardid + card?.frontimage"
                            class="slider-img-collection clickable collCard" (click)="selCardDetails(card.cardid)"
                            [ngClass]="{'card-active' : card.cardid==cardDetails?.selectedCardID}"
                            onerror="this.src = 'assets/images/card-placeholder.png'">

                        <div class="statColl">
                            <div class="hypeImgColl">
                                <img src="assets/images/icon-hype-coll.png">
                            </div>
                            <div class="hypeValueColl">{{card?.hypes || '0'}}</div>
                            <img class="watchImgColl" src="assets/images/icon-watchlist-outline.png">
                        </div>

                        <h6 class="name">{{card?.playername}}</h6>
                        <h6 class="details">{{card?.year}}<span *ngIf="card?.setname && card?.setname != ''">,</span>
                            {{card?.setname}}</h6>
                    </div>
                </a>
            </ng-container>
            <!-- <a class="text-decoration-none" *ngIf="!hideShowMore">
                <div class="collImgDiv load-more-main">
                    <div>

                        <div class="load-more load-more-main">
                            <button class="load-more-btn" (click)="loadMoreCollection()">Load More...</button>
                        </div>

                        <div class="statColl">
                            <div class="hypeImgColl">

                            </div>
                            <div class="hypeValueColl"></div>
                        </div>

                        <h6 class="name"></h6>
                        <h6 class="details"></h6>
                    </div>
                </div>
            </a> -->
        </div>
        <div class="custom-slider-arrow mr-n15 collectionScrollImg clickable" (click)="scrollCollectionRight()">
            <span class="slider-btn">
                <img src="assets/images/right-arrow.svg" alt="">
            </span>
        </div>
    </div>
</div>
<!-- Card Collection PC -->