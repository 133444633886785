// import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { CardAttributesType, UserAttributes } from '@nextgem/shared-types';
import { CardService } from '../core/Services/card-service';
import { UserService } from '../core/Services/user-service';
import { environment } from '../../environments';
import { Title, Meta } from '@angular/platform-browser';
import { HttpHeaders } from '@angular/common/http';
import { parseI18nMeta } from '@angular/compiler/src/render3/view/i18n/meta';
import { FormArray } from '@angular/forms';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInput } from '@angular/material/chips';
import { PageEvent } from '@angular/material/paginator';

// import * as CryptoJS from 'crypto-js';

@Component({
    selector: 'search-root',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
    searchForm: FormGroup;
    showSort: boolean = false;
    showFilter: boolean = false;
    showPrice: boolean = true;

    uniqSetnames: any = [];
    uniqPlayernames: any = [];
    uniqGenres: any = [];
    uniqGrades: any = [];
    uniqGraders: any = [];
    uniqYears: any = [];
    uniqTags: any = [];
    uniqCardstatus: any = [];
    minPrice: number = 0;
    maxPrice: number = 0;

    profileCards: any = [];
    userProfile: any = <UserAttributes>{};
    collectionList: any = [];
    imageUrl: any = environment.imageURL + '/';

    sortList: any = [
        { name: 'Best Match', value: '1', short: 'BestMatch' },
        { name: 'Alphabetical: A-Z', value: '2', short: 'A-Z' },
        { name: 'Alphabetical: Z-A', value: '3', short: 'Z-A' },
        { name: 'Newly Added', value: '4', short: 'New' },
        { name: 'Price: Low to High', value: '5', short: 'Price-asc' },
        { name: 'Price: High to Low', value: '6', short: 'Price-desc' },
        { name: 'Grade: High to Low', value: '7', short: 'Grade-desc' },
        { name: 'Grade: Low to High', value: '8', short: 'Grade-asc' },
        { name: 'Hypes: High to Low', value: '9', short: 'Hypes-desc' },
        { name: 'Hypes: Low to High', value: '10', short: 'Hypes-asc' },
    ];

    genreList: any = [
        { name: 'Baseball', value: 'Baseball' },
        { name: 'Basketball', value: 'Basketball' },
        { name: 'Football', value: 'Football' },
        { name: 'Hockey', value: 'Hockey' },
        { name: 'Pokemon', value: 'Pokemon' },
        { name: 'Soccer', value: 'Socer' },
        { name: 'UFC', value: 'UFC' },
        { name: 'Others', value: 'Others' },
    ];

    cardCount: any = {};

    activeFilters: { value: string; filter: string; name: string }[] = [];

    gradeSet: any = {
        min: 0,
        max: 10,
        step: 0.5,
    };

    gradeList: any = [
        { name: '10', value: 10 },
        { name: '9.5', value: 9.5 },
        { name: '9', value: 9 },
        { name: '8.5', value: 8.5 },
        { name: '8', value: 8 },
        { name: '7.5', value: 7.5 },
        { name: '7', value: 7 },
    ];

    gradeOption: any = [];

    yearSet: any = {
        min: 1865,
        max: new Date().getFullYear(),
        step: 1,
    };

    yearList: any = [
        { name: '2022', value: 2022 },
        { name: '2021', value: 2021 },
        { name: '2020', value: 2020 },
        { name: '1995', value: 1995 },
        { name: '1996', value: 1996 },
        { name: '1997', value: 1997 },
    ];

    yearOption: any = [];

    tagList: any = [
        // { name: 'On Card Auto', value: 'On Card Auto' },
        // { name: 'Sticker Auto', value: 'Label / Sticker Auto' },
        // { name: 'Cut Signiature', value: 'Cut Signiature Auto' },
        { name: 'Autograph', value: 'Autograph' },
        { name: 'Base Set', value: 'Base Set' },
        { name: 'Insert', value: 'Insert' },
        { name: 'Memorabilia', value: 'Memorabilia' },
        { name: 'Multi-Player', value: 'Multi-Player' },
        { name: 'Parellel', value: 'Parellel' },
        { name: 'Parellel Insert', value: 'Parellel Insert' },
        { name: 'Printing Plate', value: 'Printing Plate' },
        { name: 'Rookie', value: 'Rookie' },
        { name: 'Serial#', value: 'Serial#' },
        { name: 'Short-Print', value: 'Short-Print' },
    ];

    graderList: any = [
        { name: 'BGS', value: 'BGS' },
        { name: 'PSA', value: 'PSA' },
        { name: 'SGC', value: 'SGC' },
        { name: 'Other', value: 'Other' },
        { name: 'Ungraded', value: 'Ungraded' },
    ];

    cardstatusList: any = [
        { name: 'Personal Collection', value: 'Personal Collection' },
        { name: 'Open to Offers', value: 'Open to Offers' },
        { name: 'For Sale', value: 'For Sale' },
    ];

    socialIcon: any = {
        1: 'icon-twitter',
        2: 'icon-instagram',
        3: 'icon-youtube',
        4: 'icon-ebay',
        5: 'icon-myslabs',
        6: 'icon-facebook',
    };

    socialBaseURL: any = {
        1: 'https://twitter.com/',
        2: 'https://www.instagram.com/',
        3: 'https://www.youtube.com/results?search_query=',
        4: 'https://www.ebay.com/usr/',
        5: 'https://www.myslabs.com/browse/account/?owner=',
        6: 'https://www.facebook.com/',
    };

    pageLoaded: boolean = false;
    search: boolean = false;
    currentPage = 0;
    totalPages!: number;
    pageSize = 100;
    @ViewChild('scrollTargetMain') scrollTargetMainRef!: ElementRef;
    isSearchAPI = false;
    constructor(
        private cardService: CardService,
        private userService: UserService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private meta: Meta,
    ) {
        this.searchForm = this.fb.group({
            keyword: [''],
            estimatedprice: this.fb.group({
                max: [],
                min: [],
            }),
            sort: ['6'],
            pagesize: ['2000'],
            userid: [''],
            filter: this.fb.group({
                genres: this.fb.array([]),
                playernames: this.fb.array([]),
                setnames: this.fb.array([]),
                overallgrades: this.fb.array([]),
                graders: this.fb.array([]),
                years: this.fb.array([]),
                tags: this.fb.array([]),
                cardstatus: [''],
            }),
            type: [''],
        });
    }

    updateQueryParams() {
        let qp: any = {};
        for (let item of this.activeFilters) {
            console.log(item);
            if (qp[item.filter]) {
                // console.log("append to " + item.filter);
                qp[item.filter] = qp[item.filter] + ',' + item.value;
            } else {
                // console.log("create new " + item.filter);
                qp[item.filter] = item.value;
            }
        }
        let data = this.searchForm.value;
        if (data['keyword']) {
            qp['keyword'] = data['keyword'];
        }
        for (let sort of this.sortList) {
            if (data['sort'] === sort.value && sort.value != 6) {
                console.log(sort);
                qp['sort'] = sort.short;
            }
        }

        // console.log(qp);
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: qp,
            queryParamsHandling: '',
        });
    }

    ngOnInit(): void {
        // First get the parameter from the current route.
        let inputflag = 0;
        let queryParams: any = this.route.snapshot.queryParams;
        let params: any = this.route.snapshot.params;
        let filters = [
            'genres',
            'playernames',
            'setnames',
            'overallgrades',
            'graders',
            'years',
            'tags',
            'cardstatus',
            'pricerange',
        ];
        for (let i in filters) {
            if (queryParams[filters[i]]) {
                console.log(i + ':' + filters[i] + ':' + queryParams[filters[i]]);
                let item = queryParams[filters[i]].split(',');
                console.log(item);
                for (let j in item) {
                    console.log(filters[i] + '->' + item[j]);
                    this.toggleFilters(filters[i], item[j]);
                    inputflag = 1;
                    console.log(this.searchForm.value);
                }
            }
        }
        let formItem = ['sort', 'keyword'];
        for (let i in formItem) {
            if (queryParams[formItem[i]]) {
                let value = queryParams[formItem[i]];
                if (formItem[i] === 'sort') {
                    for (let sort of this.sortList) {
                        //get the sort number from the sortList to pass into API
                        if (value === sort.short) {
                            // console.log(sort);
                            value = sort.value;
                        }
                    }
                }
                const control: FormControl = this.searchForm.get(formItem[i]) as FormControl;
                control.setValue(value);
                inputflag = 1;
            }
        }

        if (inputflag) {
            // console.log("searchCollection");
            // console.log(queryParams);
            this.searchCollection();
        } else {
            // console.log("discoveryview");

            this.discoveryview();
        }
        // console.log("NextGem User Profile" + params.username);
        // this.getProfile(params.username);
        // this.titleService.setTitle(params.username + " NextGem Profile");

        // this.meta.updateTag({
        //     property: 'og:title', content: params.username + " NextGem Profile"
        // });

        this.pageLoaded = true;
    }

    onlyUnique(value: any, index: any, self: any) {
        return self.indexOf(value) === index;
    }
    onlyUniqueString(value: any, index: any, self: any[]) {
        const _value = value ? String(value).toLowerCase() : null;
        return self.findIndex(s => String(s).toLowerCase() === _value) === index;
    }

    share() {
        console.log(window.location.href);
        // this.cardDetails.sharePopup = !this.cardDetails.sharePopup;
    }

    discoveryview() {
        let data = { pagesize: this.pageSize, page: this.currentPage + 1 };
        this.search = false;
        let urlStructure: any = environment.cardServiceDomain + '/discovery/view';

        this.cardService.post(urlStructure, data).subscribe(
            (res: any) => {
                this.isSearchAPI = false;
                const cards = res?.data?.cards || [];
                this.totalPages = res?.data?.count;
                if (Object.keys(cards).length >= 0) {
                    for (let card of cards) {
                        card.urlplayername = card.playername.replace(/\s+/g, '-');
                        if (!card.title) {
                            card.title = card.playername + ' ' + card.year + ' ' + card.setname + ' ' + card.variation;
                        }
                        card.urltitle = card.title.replace(/\s+/g, '-').replace(/null/g, '');
                        card.urltitle = card.urltitle.replace(/-+/g, '-');
                        card.urltitle = encodeURIComponent(card.urltitle.replace(/-$/g, ''));
                        card.urlusername = encodeURIComponent(card.username);
                    }
                    this.collectionList = cards;
                }
            },
            err => {
                console.log('*********Page Details API*********');
                console.log(err.status);
            },
        );
    }

    // getProfile(c: string) {
    //     let urlStructure: any = environment.userServiceDomain + "/profile/" + c;
    //     console.log(urlStructure);

    //     this.userService.get(urlStructure).subscribe((res: any) => {
    //         if (Object.keys(res.data.length > 0)) {
    //             this.userProfile = res.data as UserAttributes;
    //             this.userProfile.profileimage = environment.profileURL + "/" + this.userProfile.userid;
    //             this.userProfile.shareURL = window.location.href;
    //             this.userProfile.sharePopup = false;
    //             this.searchCollection();
    //             this.meta.updateTag({
    //                 property: 'og:image', content: this.userProfile.profileimage
    //             });
    //         }
    //     },
    //         (err) => {
    //             console.log("*********Page Details API*********");
    //             console.log(err.status);
    //         });
    // }

    searchCollection() {
        // let keyword = encodeURI(this.keywords.value)
        let playernames: any = [];
        let setnames: any = [];
        let genres: any = [];
        let grades: any = [];
        let graders: any = [];
        let years: any = [];
        let tags: any = [];
        let cardstatus: any = [];
        let minPrice = 9999999999;
        let maxPrice = 0;
        this.search = true;
        // let data = { "pagesize": "2000", "keyword": keyword, "userid": this.userProfile.userid };
        let data = this.searchForm.value;
        data['pagesize'] = this.pageSize;
        data['page'] = this.currentPage + 1;
        // data['userid'] = this.userProfile.userid;
        // let headers = { 'x-nextgem-userid': this.userProfile.userid };
        // let urlStructure: any = environment.cardServiceDomain + "/es/mycollection";
        let urlStructure: any = environment.cardServiceDomain + '/discoversearch';
        this.updateQueryParams();
        // console.log(urlStructure, data, { headers });
        this.cardService.post(urlStructure, data).subscribe(
            (res: any) => {
                this.isSearchAPI = true;
                this.totalPages = res?.data?.totalcount;
                if (Object.keys(res.data.cards.length > 0)) {
                    for (let card of res.data.cards) {
                        if (card.playername) playernames.push(card.playername);
                        if (card.setnames) setnames.push(card.setnames);
                        if (card.genre) genres.push(card.genre);
                        if (card.overallgrade) grades.push(card.overallgrade);
                        if (card.grader) graders.push(card.grader);
                        if (card.year) years.push(card.year);
                        if (card.tags) {
                            for (let tag of card.tags) {
                                tags.push(tag);
                            }
                        }
                        if (card.cardstatus) cardstatus.push(card.cardstatus);
                        if (card.listingprice && card.listingprice > maxPrice) maxPrice = card.listingprice;
                        if (card.listingprice && card.listingprice < minPrice) minPrice = card.listingprice;
                        card.urlplayername = card.playername.replace(/\s+/g, '-');
                        if (!card.title) {
                            card.title = card.playername + ' ' + card.year + ' ' + card.setname + ' ' + card.variation;
                        }
                        card.urltitle = card.title.replace(/\s+/g, '-').replace(/null/g, '');
                        card.urltitle = card.urltitle.replace(/-+/g, '-');
                        card.urltitle = encodeURIComponent(card.urltitle.replace(/-$/g, ''));
                        card.urlusername = encodeURIComponent(card.username);
                        // card.username = this.userProfile.username;
                    }
                    this.collectionList = res.data.cards;
                    for (let card of this.collectionList) {
                        this.profileCards.push(card);
                        this.cardCount[card.cardstatus] = this.cardCount[card.cardstatus]
                            ? this.cardCount[card.cardstatus] + 1
                            : 1;
                    }
                    this.uniqPlayernames = playernames.filter(this.onlyUnique).sort();
                    this.uniqSetnames = setnames.filter(this.onlyUnique).sort();
                    this.uniqGenres = genres.filter(this.onlyUnique).sort();
                    this.uniqGrades = grades.filter(this.onlyUnique).sort();
                    this.uniqGraders = graders.filter(this.onlyUnique).sort();
                    this.uniqYears = years.filter(this.onlyUnique).sort();
                    this.uniqTags = tags.filter(this.onlyUnique).sort();
                    this.uniqCardstatus = cardstatus.filter(this.onlyUniqueString).sort();
                    this.maxPrice = maxPrice;
                    this.minPrice = minPrice;
                }
            },
            err => {
                console.log('*********Page Details API*********');
                console.log(err.status);
            },
        );
    }

    toggleSortView() {
        this.showSort = !this.showSort;
    }

    toggleAllFilters() {
        let tempfilters = new Array(); //make a deep copy so we don't run into iteration/delete race conditions
        for (let item of this.activeFilters) {
            tempfilters.push(item);
        }
        console.log(tempfilters + ':' + tempfilters.length);
        for (let item of tempfilters) {
            // console.log(item);
            this.toggleFilters(item.filter, item.value);
        }
    }

    toggleFilters(filter: string, value: string) {
        //add/remove buttons in the filter sidenav as well as associated actions
        const singleFilter = ['cardstatus', 'pricerange'];
        // console.log(filter + " " + value);
        const filterArray: FormArray = this.searchForm.get('filter') as FormArray;
        if (!singleFilter.includes(filter)) {
            const f: FormArray = filterArray.get(filter) as FormArray;
            const index = f.controls.findIndex(x => x.value === value);
            if (index === -1) {
                f.push(this.fb.control(value));
            } else {
                f.removeAt(index);
            }
        }
        let readPricefromValue = 1;
        if (filter === 'pricerange' && value === '') {
            //combine min-max for price button
            const filterPrice: FormArray = this.searchForm.get('estimatedprice') as FormArray;
            const maxPrice: FormControl = filterPrice.get('max') as FormControl;
            const minPrice: FormControl = filterPrice.get('min') as FormControl;

            let min = minPrice.value ? '$' + minPrice.value.toString() : '$0';
            let max = maxPrice.value ? '$' + maxPrice.value.toString() : Infinity;
            value = min + '-' + max;
            readPricefromValue = 0;
        }
        const button = { name: value, value: value, filter: filter };

        const buttonMatchIndex = this.activeFilters.findIndex(object => object.value === value);
        const buttonFilterIndex = this.activeFilters.findIndex(object => object.filter === filter);
        // console.log(buttonMatchIndex + ": " + this.activeFilters);

        if (!singleFilter.includes(filter)) {
            if (buttonMatchIndex === -1) {
                //add button
                this.activeFilters.push(button);
            } else {
                // remove button
                this.activeFilters.splice(buttonMatchIndex, 1);

                const checkbox = document.getElementById(value) as HTMLInputElement | null;
                if (checkbox != null) {
                    checkbox.checked = false;
                }
            }
        } else {
            console.log(buttonMatchIndex + ':' + buttonFilterIndex);
            if (buttonFilterIndex === -1) {
                //no matching filters
                console.log('add button'); //add button
                if (filter === 'cardstatus') {
                    //update info from passed in query param
                    const f: FormControl = filterArray.get(filter) as FormControl;
                    f.setValue(value); // force string into FormControl
                } else if (filter === 'pricerange' && readPricefromValue) {
                    //update info from passed in query param
                    const filterPrice: FormArray = this.searchForm.get('estimatedprice') as FormArray;
                    const maxPrice: FormControl = filterPrice.get('max') as FormControl;
                    const minPrice: FormControl = filterPrice.get('min') as FormControl;
                    let priceString = value.replace(/\$/g, '');
                    console.log(value + ' -> ' + priceString);
                    let price = priceString.split('-'); //min-max
                    console.log(price);
                    if (price[0]) {
                        if (price[0] === '0') {
                            minPrice.setValue(null);
                        } else {
                            minPrice.setValue(price[0]);
                        }
                    }
                    if (price[1]) {
                        if (price[1] === 'Infinity') {
                            maxPrice.setValue(null);
                        } else {
                            maxPrice.setValue(price[1]);
                        }
                    }
                }
                this.activeFilters.push(button);
            } else if (buttonFilterIndex >= 0 && buttonMatchIndex === -1) {
                //another item in category already exists
                console.log('update button');
                this.activeFilters[buttonFilterIndex].value = value; //update button info
                this.activeFilters[buttonFilterIndex].name = value;
            } else {
                //remove button
                console.log('remove button');
                if (filter === 'cardstatus') {
                    const f: FormControl = filterArray.get(filter) as FormControl;
                    f.setValue(''); // clear FormControl
                } else if (filter === 'pricerange') {
                    const filterPrice: FormArray = this.searchForm.get('estimatedprice') as FormArray;
                    const maxPrice: FormControl = filterPrice.get('max') as FormControl;
                    const minPrice: FormControl = filterPrice.get('min') as FormControl;
                    maxPrice.setValue(null);
                    minPrice.setValue(null);
                }
                this.activeFilters.splice(buttonFilterIndex, 1); //remove item from activeFilters

                const checkbox = document.getElementById(value) as HTMLInputElement | null;
                if (checkbox != null) {
                    checkbox.checked = false;
                }
            }
        }
    }

    toggle(searchId: any) {
        // console.log(searchId);
        let id = <HTMLElement>document.getElementById(searchId + 'Content');
        // console.log(id);
        let css = id.className;
        // console.log(id.className);
        if (css === 'collapsible active') {
            id.className = 'collapsible';
        } else {
            id.className = 'collapsible active';
        }
        var content = <HTMLElement>id.nextElementSibling;
        // console.log(content.style);
        if (content) {
            if (content.style.maxHeight) {
                content.style.maxHeight = '';
            } else {
                content.style.maxHeight = content.scrollHeight + 'px';
            }
        }
    }
    onSubmit(): void {
        let data = this.searchForm.value;
        let keyword = data['keyword'];
        // console.log(this.activeFilters.length); bug only discovery when no activeFilters
        if (keyword != '') {
            this.searchCollection();
        } else {
            this.updateQueryParams();
            this.discoveryview();
        }
    }

    searchOnEmpty() {
        let data = this.searchForm.value;
        let keyword = data['keyword'];
        if (keyword == '') {
            this.onSubmit();
        }
    }
    changePage(event: PageEvent) {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        if (this.isSearchAPI) {
            this.searchCollection();
        } else {
            this.discoveryview();
        }
        //get latest
        if (this.scrollTargetMainRef?.nativeElement) {
            this.scrollTargetMainRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
}
