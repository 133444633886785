import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../environments';
import { CardDetails } from '@approot/models/card-details'; // interface
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CardDetailsModalComponent } from '@approot/card-details-modal/card-details-modal.component';

@Component({
    selector: 'app-card-listing',
    templateUrl: './card-listing.component.html',
    styleUrls: ['./card-listing.component.scss']
})
export class CardListingComponent implements OnInit {
    @Input() card!: CardDetails;
    @Input() featured: boolean = false;
    constructor(private dialog: MatDialog) { }

    imageUrl: any = environment.imageURL + '/';

    ngOnInit(): void {

    }

    buyThisCardNow() {
        console.log('buy this card');

    }

    seluserProfile(cardID: string) {
        console.log('select user profile ', cardID);

    }

    openCardModalHandler() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
            card: this.card
        };

        this.dialog.open(CardDetailsModalComponent, dialogConfig);
    }

}
