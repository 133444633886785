import { Injectable } from '@angular/core';
import { SearchData } from '@approot/models/search-data';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor() { }

  selectedFilters: { // typescript types here
    genres: string[],
    minPrice: number | null,
    maxPrice: number | null,
    overallgrades: (number | string)[],
    graders: string[],
    minYear: number | string | null,
    maxYear: number | string | null
  } = { // actual values here
    genres: [], // holds genre titles, not full genre object
    minPrice: null,
    maxPrice: null,
    overallgrades: [],
    graders: [],
    minYear: null,
    maxYear: null
      }
  defaultSortOption: any = { name: 'Price: High to Low', value: '6', short: 'Price-desc' }; // holds the full selected sort option
  selectedSortOption: any = this.defaultSortOption; // holds the full selected sort option
  activeFilterCount: number = 0; // activeFilterCount controls filter icon in UI

  graderOptions = [
    { name: 'BGS', checked: false },
    { name: 'PSA', checked: false },
    { name: 'SGC', checked: false },
    { name: 'Other', checked: false },
    { name: 'Ungraded', checked: false },
  ];

  genreList: any = [
    { name: 'Baseball', value: 'Baseball', icon: 'sports_baseball' },
    { name: 'Basketball', value: 'Basketball', icon: 'sports_basketball' },
    { name: 'Football', value: 'Football', icon: 'sports_football' },
    { name: 'Hockey', value: 'Hockey', icon: 'sports_hockey' },
    { name: 'Pokemon', value: 'Pokemon', icon: 'style' },
    { name: 'Soccer', value: 'Socer', icon: 'sports_soccer' },
    { name: 'Wrestling', value: 'Wrestling', icon: 'sports_kabaddi' },
    { name: 'UFC', value: 'UFC', icon: 'sports_mma' },
    { name: 'Others', value: 'Others', icon: 'more_horiz' }
  ];

  allGradeOptions = [
    { grade: 10, id: 10, checked: false },
    { grade: 9.5, id: 9.5, checked: false },
    { grade: 9, id: 9, checked: false },
    { grade: 8.5, id: 8.5, checked: false },
    { grade: 8, id: 8, checked: false },
    { grade: '7.5 and under', id: '7.5under', checked: false },
    { grade: 7.5, id: 7.5, checked: false },
    { grade: 7, id: 7, checked: false },
    { grade: 6.5, id: 6.5, checked: false },
    { grade: 6, id: 6, checked: false },
    { grade: 5.5, id: 5.5, checked: false },
    { grade: 5, id: 5, checked: false },
    { grade: 4.5, id: 4.5, checked: false },
    { grade: 4, id: 4, checked: false },
    { grade: 3.5, id: 3.5, checked: false },
    { grade: 3, id: 3, checked: false },
    { grade: 2.5, id: 2.5, checked: false },
    { grade: 2, id: 2, checked: false },
    { grade: 1.5, id: 1.5, checked: false },
    { grade: 1, id: 1, checked: false }
  ];

  sortList: any = [
    { name: 'Best Match', value: '1', short: 'BestMatch' },
    { name: 'Alphabetical: A-Z', value: '2', short: 'A-Z' },
    { name: 'Alphabetical: Z-A', value: '3', short: 'Z-A' },
    { name: 'Newly Added', value: '4', short: 'New' },
    { name: 'Price: Low to High', value: '5', short: 'Price-asc' },
    { name: 'Price: High to Low', value: '6', short: 'Price-desc' },
    { name: 'Grade: High to Low', value: '7', short: 'Grade-desc' },
    { name: 'Grade: Low to High', value: '8', short: 'Grade-asc' },
    { name: 'Hypes: High to Low', value: '9', short: 'Hypes-desc' },
    { name: 'Hypes: Low to High', value: '10', short: 'Hypes-asc' }
  ];

  /**
   * Takes a value for sorting and finds the full sort option from sortList
   * @param option may be value, short, or even name
   */
  setSelectedSortOption (option: string) {
    let sortOption: any;
    sortOption = this.sortList.find((sort: any) => sort.value == option); // look by value
    if (!sortOption) {
      sortOption = this.sortList.find((sort: any) => sort.short == option); // look by short
      if (!sortOption) {
        sortOption = this.sortList.find((sort: any) => sort.name == option); // look by name
      }
    }
    this.selectedSortOption = sortOption;
  }

  /**
     * When search params are sent via the URL, this function
     * sets the UI elements to match the search parameters
     * @param searchParams 
     */
   updateUIFromSearchParams (searchParams: SearchData): void {
    // console.log(searchParams);
    if (searchParams.estimatedprice.min) {
      this.selectedFilters.minPrice = searchParams.estimatedprice.min;
    }
    if (searchParams.estimatedprice.max) {
      this.selectedFilters.maxPrice = searchParams.estimatedprice.max;
    }
    if (searchParams.filter.genres.length) {
      this.selectedFilters.genres = [...searchParams.filter.genres];
    }
    if (searchParams.filter.overallgrades.length) {
      this.selectedFilters.overallgrades = [...searchParams.filter.overallgrades];
    }
    if (searchParams.filter.graders.length) {
      this.selectedFilters.graders = [...searchParams.filter.graders];
    }
    if (searchParams.filter.years.length) {
      // sort years from params just in case things got out of order at any point
      const years = searchParams.filter.years.sort((a: string, b: string) => Number(a) - Number(b));
      this.selectedFilters.minYear = years[0];
      this.selectedFilters.maxYear = years[years.length - 1];
    }
    this.setActiveFilterCount();
  }

  /**
   * Updates the number of active filters set by the user
   */
  setActiveFilterCount (): void { 
    let count = 0;
    if (this.selectedFilters.genres.length) {
      count++;
    }
    if (this.selectedFilters.overallgrades.length) {
      count++;
    }
    if(this.selectedFilters.graders.length) {
      count++;
    }
    if (this.selectedFilters.minPrice) {
      count++;
    }
    if (this.selectedFilters.maxPrice) {
      count++;
    }
    if (this.selectedFilters.minYear) {
      count++;
    }
    if (this.selectedFilters.maxYear) {
      count++;
    }
    this.activeFilterCount = count;
  }

  resetActiveFilterCount(): void {
    this.activeFilterCount = 0;
  }

  resetAllFilters(): void {
    this.resetActiveFilterCount();
    this.selectedFilters.genres = [];
    this.selectedFilters.graders = [];
    this.resetGraderOptions();
    this.selectedFilters.overallgrades = [];
    this.resetAllGradeOptions();
    this.selectedFilters.minPrice = 0;
    this.selectedFilters.maxPrice = 0;
    this.selectedFilters.minYear = 0;
    this.selectedFilters.maxYear = 0;
  }

  resetGraderOptions (): void {
    this.graderOptions = this.graderOptions.map((grader: any) => {
      grader.checked = false;
      return grader;
    });
  }

  resetAllGradeOptions (): void {
    this.allGradeOptions = this.allGradeOptions.map((grade: any) => {
      grade.checked = false;
      return grade;
    });
  }

  setMinPrice(price: number): void {
    this.selectedFilters.minPrice = price;
    this.setActiveFilterCount();
  }

  setMaxPrice(price: number): void {
    this.selectedFilters.maxPrice = price;
    this.setActiveFilterCount();
  }

  setMinYear(year: number): void {
    this.selectedFilters.minYear = year;
    this.setActiveFilterCount();
  }

  setMaxYear(year: number): void {
    this.selectedFilters.maxYear = year;
    this.setActiveFilterCount();
  }

  
}