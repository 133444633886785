<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="primary">close</mat-icon>
</button>
<h1 mat-dialog-title class="text-center">
    {{title}}
</h1>

<div mat-dialog-content>
    <p class="dialog-sub-text text-center">{{message}}</p>
</div>

<div mat-dialog-actions class="mb-0">
    <button class="btn btn-block sing-up" mat-raised-button color="accent" (click)="signup()">Sign Up</button>
    <button class=" btn btn-block btn-link ml-0 text-white text-decoration-none">
        <span class="already-have-account">Already have an account? </span><span class="login-text"
            (click)="onConfirm()"> Log in</span>
    </button>
</div>