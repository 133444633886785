import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, ElementRef, Input, OnInit, ViewChild, Output, EventEmitter, HostListener } from '@angular/core';
import { CardAttributesType } from '@nextgem/shared-types';

interface cardDetails {
    text: string;
    title: string;
}
interface CardData extends CardAttributesType {
    cardDetails?: cardDetails[];
}

@Component({
    selector: 'app-card-view-web',
    templateUrl: './card-view-web.component.html',
    styleUrls: ['./card-view-web.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ transform: 'translateX(-100%)' }),
                animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
            ]),
            transition(':leave', [animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))]),
        ]),
    ],
})
export class CardViewWebComponent implements OnInit {
    @Input() cardDetails: any;
    @ViewChild('widgetsContent', { static: false })
    widgetsContent: ElementRef | any;
    @Input() source: string | any;
    @Output() setCard: EventEmitter<any> = new EventEmitter();
    @Output() copyInputMessage: EventEmitter<any> = new EventEmitter();
    @Output() eventFromChild: EventEmitter<any> = new EventEmitter();
    isImageExpanded: boolean = false;
    @ViewChild('scrollContainer') scrollContainer!: ElementRef;
    constructor() {}
    @HostListener('scroll', ['$event'])
    onScroll(event: Event) {
        const element = this.scrollContainer.nativeElement;
        // Check if the user has scrolled to the right end
        if (Math.ceil(element.scrollLeft) + element.offsetWidth >= element.scrollWidth) {
            // Perform pagination or any other action
            this.loadMoreCollection();
        }
    }

    ngOnInit(): void {}

    openFullImage() {
        this.isImageExpanded = !this.isImageExpanded;
    }

    scrollLeft() {
        this.widgetsContent.nativeElement.scrollLeft -= 300;

        let scrollLeft = this.widgetsContent.nativeElement.scrollLeft;

        if (scrollLeft < 300) {
            this.cardDetails.slideLeftArrow = false;
            this.cardDetails.slideRightArrow = true;
        } else {
            this.cardDetails.slideLeftArrow = true;
            this.cardDetails.slideRightArrow = true;
        }
    }

    scrollRight() {
        this.widgetsContent.nativeElement.scrollLeft += 300;

        let offsetWidth = this.widgetsContent.nativeElement.offsetWidth;
        let scrollLeft = this.widgetsContent.nativeElement.scrollLeft;
        let scrollWidth = this.widgetsContent.nativeElement.scrollWidth;

        if (scrollWidth - (offsetWidth + scrollLeft) < 300) {
            this.cardDetails.slideLeftArrow = true;
            this.cardDetails.slideRightArrow = false;
        } else {
            this.cardDetails.slideLeftArrow = true;
            this.cardDetails.slideRightArrow = true;
        }
    }

    scrollCollectionLeft() {
        let leftScroll: any = document.getElementById('collectionScroll');
        leftScroll.scrollLeft -= 1000;
    }

    scrollCollectionRight() {
        let rightScroll: any = document.getElementById('collectionScroll');
        rightScroll.scrollLeft += 1000;
    }

    selCardDetails(cid: any) {
        this.setCard.emit(cid);
    }

    viewImage(tagIndx: any) {
        let sliderTag = document.getElementsByClassName('desk slider-img');
        for (var i: any = 0; i < sliderTag.length; i++) {
            sliderTag[i].classList.remove('card-active');
            if (i == tagIndx) {
                sliderTag[i].classList.add('card-active');
            }
        }

        this.cardDetails.presentImageIndx = tagIndx;
    }

    copyMessage(value: any) {
        this.copyInputMessage.emit(value);
    }
    sendEventToparent(eventName: string) {
        this.eventFromChild.emit(eventName);
    }
    magnify(zoom: any) {
        let img: any = document.getElementById('myimage');
        let result: any = document.getElementById('myresult');
        let cx: any;
        let cy: any;

        /*create magnifier glass:*/
        let glass: any = document.createElement('DIV');
        glass.setAttribute('class', 'img-magnifier-glass');

        /*insert magnifier glass:*/
        img.parentElement.insertBefore(glass, img);
        result.style.backgroundImage = "url('" + img.src + "')";
        result.style.backgroundRepeat = 'no-repeat';
        result.style.backgroundSize = img.offsetWidth * zoom + 'px ' + img.offsetHeight * zoom + 'px';
        cx = 4;
        cy = 4;

        /*execute a function when someone moves the magnifier glass over the image:*/
        glass.addEventListener('mousemove', moveMagnifier);
        img.addEventListener('mousemove', moveMagnifier);
        glass.addEventListener('touchmove', moveMagnifier);
        img.addEventListener('touchmove', moveMagnifier);
        glass.addEventListener('touchenter', moveMagnifier);
        img.addEventListener('touchenter', moveMagnifier);
        glass.addEventListener('mouseenter', mouseenter);
        img.addEventListener('mouseenter', mouseenter);
        glass.addEventListener('mouseleave', removeMagnifier);
        img.addEventListener('mouseleave', removeMagnifier);
        glass.addEventListener('touchleave', removeMagnifier);
        img.addEventListener('touchleave', removeMagnifier);

        function moveMagnifier(e: any) {
            var pos, x, y;
            /* Prevent any other actions that may occur when moving over the image */
            e.preventDefault();
            /* Get the cursor's x and y positions: */
            pos = getCursorPos(e);
            /* Calculate the position of the lens: */
            x = pos.x - glass.offsetWidth / 2;
            y = pos.y - glass.offsetHeight / 2;
            /* Prevent the lens from being positioned outside the image: */
            if (x > img.width - glass.offsetWidth) {
                x = img.width - glass.offsetWidth;
            }
            if (x < 0) {
                x = 0;
            }
            if (y > img.height - glass.offsetHeight) {
                y = img.height - glass.offsetHeight;
            }
            if (y < 0) {
                y = 0;
            }
            /* Set the position of the lens: */
            glass.style.left = x + 'px';
            glass.style.right = y + 'px';
            glass.style.top = y + 'px';
            glass.style.bottom = x + 'px';
            /* Display what the lens "sees": */
            result.style.backgroundPosition = '-' + x * cx + 'px -' + y * cy + 'px';
        }

        function mouseenter(e: any) {
            result.style.backgroundImage = "url('" + img.src + "')";
            result.style.backgroundSize = img.width * cx + 'px ' + img.height * cy + 'px';
            result.style.display = 'block';
        }

        function removeMagnifier(e: any) {
            var none = '';
            glass.style.backgroundImage = "url('" + none + "')";
            result.style.backgroundImage = "url('" + none + "')";
            result.style.display = 'none';
        }

        function getCursorPos(e: any) {
            var a,
                x = 0,
                y = 0;
            e = e || window.event;
            /*get the x and y positions of the image:*/
            a = img.getBoundingClientRect();
            /*calculate the cursor's x and y coordinates, relative to the image:*/
            x = e.pageX - a.left;
            y = e.pageY - a.top;
            /*consider any page scrolling:*/
            x = x - window.pageXOffset;
            y = y - window.pageYOffset;
            return { x: x, y: y };
        }
    }
    onImgError(event: any) {
        event.target.src = this.cardDetails['sendOfferPlaceholderImage'];
    }
    loadMoreCollection() {
        console.log('load more befoe');

        if (!this.hideShowMore) {
            console.log('load more');

            this.eventFromChild.next('load_more');
        }
    }
    getEventFromChild(eventName: string) {
        this.eventFromChild.emit(eventName);
    }
    get hideShowMore() {
        const totalPages = isNaN(this.cardDetails?.totalPages)
            ? this.cardDetails?.totalPages
            : Number(this.cardDetails?.totalPages);
        const totalCardsLength = this.cardDetails?.cardList?.length;
        return totalPages == totalCardsLength || totalCardsLength > totalPages;
    }
}
