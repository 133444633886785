import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { tabOption } from '@approot/core/lib/constants/common-constants';

@Component({
    selector: 'app-card-details-view',
    templateUrl: './card-details-view.component.html',
    styleUrls: ['./card-details-view.component.scss'],
    animations: [],
})
export class CardDetailsComponent implements OnInit {
    @Input() cardDetails: any;
    @Output() copyMessage: EventEmitter<any> = new EventEmitter();
    @Output() eventFromChild: EventEmitter<any> = new EventEmitter();
    copyAction: boolean = false;
    tabOptions = tabOption;
    selectedTab = 'cardInfo';
    ngOnInit(): void {}

    copyInput(value: string, type: string) {
        if (type == 'url') {
            // Copy Page URL
            this.copyMessage.emit(value);
        } else {
            // Copy NextGem ID
            this.copyMessage.emit(value);
            this.copyAction = true;
            setTimeout(() => {
                this.copyAction = false;
            }, 3000);
        }
    }
    sendEventToparent(eventName: string) {
        this.eventFromChild.emit(eventName);
    }
    get showBuyNow() {
        return String(this.cardDetails?.cardstatus).toLowerCase() === 'for sale';
    }
    share() {
        this.cardDetails.sharePopup = !this.cardDetails.sharePopup;
    }
}
