<div> <!-- width is set by profile-view.component.scss -->
    <div class="image" *ngIf="userProfile.profileimage">
        <img class="profile-img" [src]=userProfile.profileimage  onError="this.src='assets/images/weblogo.png';">
    </div>
    <div class="profile-username" *ngIf="userProfile.username">{{userProfile.username}}</div>
    <div>{{userProfile.fullname}}</div>
    <div class="profile-bio" *ngIf="userProfile.bio">{{userProfile.bio}}</div>

    <mat-grid-list cols="3" rowHeight="50px">
        <ng-container *ngFor="let media of $any(userProfile).socialmedia">
            <mat-grid-tile>
                <a class="social-link" href="{{socialBaseURL[media.socialmediaid]}}{{media.username}}">                        
                    <img class="social-img" src="assets/images/{{socialIcon[media.socialmediaid]}}.png"
                        alt="{{media.username}}" title="{{media.username}}"></a>
            </mat-grid-tile>
        </ng-container>
    </mat-grid-list>

    <mat-grid-list cols="1"  rowHeight="100px">
        <mat-grid-tile>
            <button mat-button (click)="copyShareProfleURL()"
                (click)="tooltip.show()"
                matTooltip="URL copied to clipboard!"
                #tooltip="matTooltip"
                (mouseenter)="$event.stopImmediatePropagation()"
                (mouseleave)="tooltip.hide()"
                style="background-color: #00d8ff; color: #14111f;">
                <mat-icon>share</mat-icon>
                Share Profile
            </button>
        </mat-grid-tile>
        <!-- <mat-grid-tile>
            <button mat-flat-button color="basic">
                <mat-icon>chat</mat-icon>
                Chat
            </button> 
        </mat-grid-tile> -->
    </mat-grid-list>

    <mat-grid-list cols="3" rowHeight="100px">
        <mat-grid-tile>
            <div class="profile-stats-box">
                <div class="profile-stats-count">{{collectionLength || 0}}</div>
                <div class="profile-stats-subtitle">Cards</div>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div class="profile-stats-box">
                <div class="profile-stats-count">{{userProfile.following.length || 0}}</div>
                <div class="profile-stats-subtitle">Following</div>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div class="profile-stats-box">
                <div class="profile-stats-count">{{userProfile.followers.length || 0}}</div>
                <div class="profile-stats-subtitle">Followers</div>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
</div>
<!-- <div *ngIf="userProfile" class="profileInputs">
  
  <div class="profile-details">          -->
      
          <!-- <div class="clickable copyImg share" (click)="share()" title="share">
              <img src="assets/images/icon-copy.png">
          </div>
          <div *ngIf="userProfile?.sharePopup" class="share-popup">
              <input type="text" value="{{userProfile?.shareURL}}">
              <img class="clickable" src="assets/images/icon-copy.png" title="copy" (click)="copyInput(userProfile?.shareURL, 'url');share();">
              <img src="assets/images/close-btn.png" title="close" class="share-close clickable" (click)="share()">
          </div> -->
      <!-- <div class="break"></div>
      <div *ngIf="userProfile?.followers" class="row">            
          <div class="share">{{userProfile?.followers.length || '0'}} followers</div>
          <div class="share">{{userProfile?.following.length || '0'}} following</div>

      </div>

      
  </div>
</div> -->