import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments';
import { CardAttributesType, UserAttributes } from '@nextgem/shared-types';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    userProfile: any = <UserAttributes>{};
    constructor(private http: HttpClient, private meta: Meta, private router: Router) {}

    get<T>(url: string) {
        return this.http.get<T>(url);
    }

    post(url: string, data: any) {
        return this.http.post(url, data);
    }

    /**
     * This retrieves the user profile data and populates this.userProfile
     * @param username
     */
    async getProfile(username: string) {
        let urlStructure: any = environment.userServiceDomain + '/profile/' + username;
        console.log('url user service', urlStructure);

        this.get(urlStructure).subscribe(
            (res: any) => {
                if (Object.keys(res.data.length > 0)) {
                    this.userProfile = res.data as UserAttributes;
                    this.userProfile.profileimage = environment.profileURL + '/' + this.userProfile.userid;
                    this.userProfile.shareURL = window.location.href;
                    this.userProfile.sharePopup = false;
                    this.meta.updateTag({
                        property: 'og:image',
                        content: this.userProfile.profileimage,
                    });
                }
            },
            err => {
                console.log('*********Page Details API*********');
                // console.log(err.status);
                this.router.navigate(['/search']);
            },
        );
    }
}
