<mat-card class="card-details-modal">
    <!-- <mat-card-header class="card-details-header">
        <div style="flex-grow: 1;"></div>
        <button mat-icon-button class="card-details-close"
            mat-dialog-close="true">
            <mat-icon>close</mat-icon>
        </button>
    </mat-card-header> -->
    <a [href]="urlEncode(card.username) + '/' + urlEncode(card.title) + '/' + card.cuid">
        <img mat-card-image [alt]="card.title" class=""
            [src]="imageUrl + card.cardid + card.frontimagesthumb.perspectivecorrectedthumbnail">
        <mat-card-title>{{ card.playername }}</mat-card-title>
        <mat-card-subtitle class="card-subtitle">{{ card.year }} {{ card.setname }}</mat-card-subtitle>
    </a>
    <span class="card-status">{{ card.cardstatus }} </span>
    <span *ngIf="card.cardstatus === 'For Sale'">{{ card.listingprice | currency:'USD':'symbol' }}</span>
    <hr />
    <!-- TODO: Verify this is the correct attribute name for card story -->
    <p *ngIf="card.cardstory">{{ card.cardstory }}</p>
    <mat-card-actions class="mat-card-actions">
        <!-- TODO: Add in additional check for verified seller -->
        <!-- <button mat-button class="buy-now-button" *ngIf="card.cardstatus === 'For Sale'">Buy Now</button> -->
        <button mat-stroked-button class="share-card-button"
            (click)="copyShareLink()"
            (click)="tooltip.show()"
            matTooltip="Link copied to clipboard!"
            #tooltip="matTooltip"
            (mouseenter)="$event.stopImmediatePropagation()"
            (mouseleave)="tooltip.hide()">
            <mat-icon>open_in_new</mat-icon>
            Share Card Link
        </button>
    </mat-card-actions>
</mat-card>