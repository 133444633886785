import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { AppComponent } from './app.component';
import { CardViewComponent } from './card-view/card-view.component';
import { UserProfileViewComponent } from './profile/profile-view.component';
import { SearchComponent } from './search/search.component';
import { DbSearchComponent } from './search/dbsearch/dbsearch.component';
import { OpenSearchComponent } from './search/opensearch/opensearch.component';
import { EmailUploadComponent } from './email-upload/email-upload.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CardViewWebComponent } from './card-view/card-view-web/card-view-web.component';
import { CardDetailsComponent } from './card-view/card-details-view/card-details-view.component';
import { TokenInterceptor } from './core/Services/http-interceptors';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { FilterModalComponent } from './filter-modal/filter-modal.component';
import { CardListingComponent } from './card-listing/card-listing.component';
import { CardDetailsModalComponent } from './card-details-modal/card-details-modal.component';
import { NoResultsComponent } from './no-results/no-results.component';
import { ConfirmDialogComponent } from './confirm-modal/confirm-dialog.component';
import { LoaderService } from './core/Services/loading.service';
import { LoaderInterceptor } from './core/Services/loading.interceptor';
import { LoadingComponent } from './loading/loading.component';
import { ShareCardComponent } from './card-view/share-card/share-card.component';

@NgModule({
    declarations: [
        AppComponent,
        CardViewComponent,
        UserProfileViewComponent,
        SearchComponent,
        DbSearchComponent,
        OpenSearchComponent,
        NotFoundComponent,
        CardViewWebComponent,
        CardDetailsComponent,
        EmailUploadComponent,
        UserProfileComponent,
        FilterModalComponent,
        CardListingComponent,
        CardDetailsModalComponent,
        NoResultsComponent,
        ConfirmDialogComponent,
        LoadingComponent,
        ShareCardComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    entryComponents: [FilterModalComponent, CardDetailsModalComponent, ConfirmDialogComponent],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        LoaderService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
